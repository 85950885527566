import Sidebar from "./sidebar";
import React, { useState, useEffect, useCallback } from "react";
import { AiOutlineFolder } from "react-icons/ai";
import Select from "react-select";
import CreditIndicator from "./creditIndicators";
import Loader from "./Loader";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { MyContext } from "../myContext";
import { BiGlobe } from "react-icons/bi";
import { useLayoutContext } from "../myContext/layoutContext";
import folderIcon from "../images/Folder.svg";
import globIcon from "../images/Globe.svg";
import { useLocation } from "react-router-dom";
import SingleValue from "react-select/dist/declarations/src/components/SingleValue";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    fontSize: "14px",
    padding: "2px",
    minHeight: "44px !important",
    width: "282px !important",
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#181B20",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#181B20",
    width: "223px !important",
    height: "18px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    lineHeight: "17.6px",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "5px", // Add some space between the control and the menu
    borderRadius: "10px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px", // Change this to your desired font size
    fontWeight: "bold", // Change this to your desired font weight
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

const customStyles1 = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    fontSize: "14px",
    padding: "2px",
    minHeight: "44px !important",
    width: "282px !important",
  }),
  input: (provided: any) => ({
    ...provided,
    paddingLeft: 25,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#181B20",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: "28px !important",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#181B20",
    width: "200px !important",
    height: "18px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    lineHeight: "17.6px",
    marginLeft: "28px",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "5px",
    borderRadius: "10px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    fontWeight: "bold",
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

const customStyles2 = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    fontSize: "14px",
    padding: "2px",
    minHeight: "44px !important",
    width: "282px !important",
  }),
  input: (provided: any) => ({
    ...provided,
    paddingLeft: 25,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: "28px !important",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#181B20",
    width: "200px !important",
    height: "18px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    marginLeft: "28px",
    lineHeight: "17.6px",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#181B20",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "5px", // Add some space between the control and the menu
    borderRadius: "10px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px", // Change this to your desired font size
    fontWeight: "bold", // Change this to your desired font weight
  }),
  valuecontainer: (provided: any) => ({
    marginLeft: "25px !important",
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

const Layout = ({ children, setProjectId, setOrganizationId }: any) => {
  const location = useLocation();
  const base_url = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [projectOptions, setProjectOptions] = useState<any[]>([]);
  const [environmentOption, setEnvironmentOption] = useState<any>([]);
  const [selectedEnv, setSelectedEnv] = useState<any>(null);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [orgLoading, setOrgLoading] = useState<boolean>(true);
  const [projectLoading, setProjectLoading] = useState<boolean>(true);
  const [EnvLoading, setEnvLoading] = useState<boolean>(true);
  const {
    isAuthenticated,
    user,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [isUserChecked, setIsUserChecked] = useState(false);
  const getOrgList = useCallback((token: any) => {
    setOrgLoading(true);
    axios
      .get(`${base_url}/api/v1/organizations/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const options = res?.data?.map((item: any) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          });
          localStorage.setItem("orgData", JSON.stringify(options[0]));
          setOptions(options);
          setSelectedOrg(options[0]);
          getProject(token, options[0]?.value);
          setOrganizationId(options[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setOrgLoading(false));
  }, []);
  const customFilterOption = (option: any, rawInput: any) => {
    const label = option.label.toLowerCase();
    const input = rawInput.toLowerCase();

    return label.includes(input);
  };

  const getProject = (token: any, orgId: any) => {
    setProjectLoading(true);
    axios
      .get(`${base_url}/api/v1/organization/${orgId}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.length !== 0) {

          const options = res?.data?.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          res?.data.length > 0
            ? localStorage.setItem("projectData", JSON.stringify(options[0]))
            : localStorage.setItem(
                "projectData",
                JSON.stringify({ label: "", value: "" })
              );
          setProjectOptions(options);
          setSelectedProject(options[0]);
          setProjectId(options[0]);
          getEnvironment(token, options[0]?.value);
        } else {
          setProjectOptions([]);
          getEnvironment(token, options[0]?.value);
          setSelectedProject([]);
          setProjectId("");
        }
      })
      .catch((e) => {
        setEnvironmentOption([]);
        localStorage.setItem(
          "envData",
          JSON.stringify({ label: "", value: "" })
        );
      })
      .finally(() => setProjectLoading(false));
  };

  const getEnvironment = (token: any, projectId: any) => {
    try {
      setEnvLoading(true);
      axios
        .get(`${base_url}/api/v1/environment/?project_id=${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const projectData = localStorage.getItem("projectData");

          if (res.status === 200 && res.data.length !== 0) {
            const options = res?.data?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }));

            if (res?.data.length > 0) {
              localStorage.setItem("envData", JSON.stringify(options[0]));
            }
            //  else if() {
            //   localStorage.setItem("envData", JSON.stringify({ label: "", value: "" }));
            // }
            else {
              localStorage.setItem(
                "envData",
                JSON.stringify({ label: "", value: "" })
              );
            }

            setEnvironmentOption(options);
            setSelectedEnv(options[0]);
          } else {
            setEnvironmentOption([]);
            setSelectedEnv(null);
          }
        })
        .catch((error) => {
          setEnvironmentOption([]);
          setSelectedEnv(null);
          localStorage.setItem(
            "envData",
            JSON.stringify({ label: "", value: "" })
          );
        })
        .finally(() => setEnvLoading(false));
    } catch (err) {
      console.error("err");
      setEnvLoading(false);
    }
  };

  const handleSelectChange = (select: any) => {
    setSelectedOrg(select);
    getProject(token, select.value);
    setOrganizationId(select);
    localStorage.setItem("orgData", JSON.stringify(select));
  };

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          localStorage.setItem("token", token);
          setToken(token);
          token && getOrgList(token);
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (!isLoading && !error) {
      setIsUserChecked(true);
    }
  }, [isLoading, error]);
  useEffect(() => {
  }, [selectedProject]);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader size={60} color={"#224BF8"} loading={isLoading} />
      </div>
    );
  }

  if (isUserChecked) {
    if (!user?.name) {
      loginWithRedirect();
    } else {
      return (
        <>
          <div
            className={`bg-[#EDF0F5] flex ${
              location.pathname === "/defect-management" ? " h-[1262px]" : ""
            }`}
          >
            {/* <Sidebar /> */}
            <div className="flex flex-col m-10 w-[1424px] h-[920px]">
              <div
                className={`rounded-[1rem] p-[24px] bg-white flex flex-col ${
                  location.pathname === "/defect-management" ? "h-[520px]" : "h-[920px]"
                }`}
              >
                <div className="font-[700] w-full rounded-2xl gap-[16px] mb-[16px]">
                  <div className="flex flex-row gap-2 justify-between">
                    <div className="flex gap-4 w-full">
                      {/* @ts-ignore */}

                      <div title={selectedOrg.label}>
                        <Select
                          defaultValue={options[0]}
                          onChange={handleSelectChange}
                          options={options}
                          value={selectedOrg}
                          styles={customStyles}
                          className="h-[44px] w-[282px]"
                          isLoading={orgLoading}
                          filterOption={customFilterOption}
                          components={
                            {
                              // SingleValue: customSingleValue,
                            }
                          }
                        />
                      </div>
                      <div className="relative">
                        <div
                          title={selectedProject ? selectedProject.label : ""}
                        >
                          <img
                            src={folderIcon}
                            alt="frame"
                            className="absolute z-10 top-[10px] left-3 text-xl"
                          />
                          <Select
                            defaultValue={projectOptions[0]}
                            filterOption={customFilterOption}
                            onChange={(select) => {
                              setSelectedProject(select);
                              getEnvironment(token, select.value);
                              setProjectId(select);

                              localStorage.setItem(
                                "projectData",
                                JSON.stringify(select)
                              );
                            }}
                            options={projectOptions}
                            value={selectedProject}
                            styles={customStyles1}
                            className="h-[44px] w-[282px]"
                            isLoading={projectLoading}
                          />
                        </div>
                      </div>

                      <div
                        title={selectedEnv ? selectedEnv.label : ""}
                        className="relative"
                      >
                        <img
                          src={globIcon}
                          alt="frame"
                          className="absolute z-10 top-[10px] left-3 text-xl"
                        />
                        <Select
                          defaultValue={environmentOption[0]}
                          filterOption={customFilterOption}
                          onChange={(select) => {
                            setSelectedEnv(select);
                            localStorage.setItem(
                              "envData",
                              JSON.stringify(select)
                            );
                          }}
                          options={environmentOption}
                          value={selectedEnv}
                          styles={customStyles2}
                          className="h-[44px] w-[282px]"
                          isLoading={EnvLoading}
                        />
                      </div>
                      <div className="w-[262px] h-[44px] ml-[177px]">
                        {location.pathname === "/" && <CreditIndicator />}
                      </div>
                    </div>
                  </div>
                </div>
                <MyContext.Provider
                  value={{
                    token,
                    EnvLoading,
                    projectLoading,
                    orgLoading,
                    selectedOrg,
                    selectedEnv,
                    selectedProject,
                    environmentOption,
                  }}
                >
                  <div className="">{children}</div>
                </MyContext.Provider>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};
export default Layout;
