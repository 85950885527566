import React from "react";

const PauseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons / Pause Circle" opacity="0.8">
        <path
          id="Color"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.66675 7.50008C6.66675 7.04175 7.04175 6.66675 7.50008 6.66675C7.95841 6.66675 8.33341 7.04175 8.33341 7.50008V12.5001C8.33341 12.9584 7.95841 13.3334 7.50008 13.3334C7.04175 13.3334 6.66675 12.9584 6.66675 12.5001V7.50008ZM11.6663 7.50008C11.6663 7.04175 12.0413 6.66675 12.4997 6.66675C12.958 6.66675 13.333 7.04175 13.333 7.50008V12.5001C13.333 12.9584 12.958 13.3334 12.4997 13.3334C12.0413 13.3334 11.6663 12.9584 11.6663 12.5001V7.50008ZM10.0001 16.6667C6.32425 16.6667 3.33341 13.6759 3.33341 10.0001C3.33341 6.32425 6.32425 3.33341 10.0001 3.33341C13.6759 3.33341 16.6667 6.32425 16.6667 10.0001C16.6667 13.6759 13.6759 16.6667 10.0001 16.6667ZM10.0001 1.66675C5.40508 1.66675 1.66675 5.40508 1.66675 10.0001C1.66675 14.5951 5.40508 18.3334 10.0001 18.3334C14.5951 18.3334 18.3334 14.5951 18.3334 10.0001C18.3334 5.40508 14.5951 1.66675 10.0001 1.66675Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default PauseIcon;
