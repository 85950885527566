import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function ProtectedRoute({ component: Component, ...rest }:any) {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      {...rest}
      render={(props:any) =>
        isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />
      }
    />
  );
}

export default ProtectedRoute;