import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutContext } from "../myContext/layoutContext";
import axios from "axios";
import { useMyContext } from "../myContext";
export type TabNavigatorTabType = {
  id: string;
  label: string;
  active?: boolean;
  hideCredits?: boolean;
  link?: string;
  onClick?: (...params: any[]) => any;
};
interface Props {
  tabs: TabNavigatorTabType[];
  flex?: boolean;
}
const TabNavigator: React.FC<Props> = ({ tabs, flex }) => {
  const { token, selectedEnv, selectedProject }: any = useMyContext();
  const base_url = process.env.REACT_APP_API_URL;
  const [badgeCount, setBadgeCount] = useState<any>();
  const { setShowCreditIndicator } = useLayoutContext();
  const router = useNavigate();
  const onClickHandler = useCallback(
    ({ link, onClick, active, hideCredits }: Partial<Props["tabs"][0]>) => {
      if (!active) {
        setShowCreditIndicator(!hideCredits);
        if (link) router(link);
        if (onClick) onClick?.();
      }
    },
    []
  );

  const getTestingQueBadgedata = async () => {
    try {
      const envQuery = selectedEnv ? selectedEnv?.value : "";
      const response = await axios.get(
        `${base_url}/api/v1/project/${selectedProject?.value}/${envQuery}/testcase_executed`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBadgeCount(response?.data?.count);
        return;
      }
      setBadgeCount(null);
    } catch (err) {
      setBadgeCount(null);
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedProject?.value && selectedEnv?.value) {
      getTestingQueBadgedata();
    }
  }, [selectedProject?.value, selectedEnv?.value]);
  useEffect(() => {
    console.log(badgeCount, "badgecount");
  }, [badgeCount]);
  return (
    <div
      className={`flex items-center justify-between ${flex ? "" : " w-full"}`}
    >
      <div className="flex p-[4px] h-[44px] w-[878px] justify-around rounded-lg bg-[#EDF0F5]">
        {tabs.map(
          ({ id, label, active, link, onClick, hideCredits }, index) => (
            <div
              id={id}
              key={index}
              className={`flex justify-center gap-2 cursor-pointer text-[#181B20] text-[16px] leading-[20.11px] 
               !w-[217.5px] h-[36px] py-2 text-center ${
                 active
                   ? "bg-white font-bold shadow-tab box_Shadow"
                   : "font-semibold opacity-80 "
               }  rounded-[8px] `}
              onClick={() =>
                onClickHandler({ active, onClick, link, hideCredits })
              }
            >
              {label === "Testing Que" &&
                badgeCount != null &&
                badgeCount !== undefined && (
                  <div className="bg-[#becff7] rounded px-[4px] py-[3px] flex items-center w-[27px] h-[20px]">
                    <span className="text-[#004CFF] text-[12px] leading-[14px] w-[19px] h-[13px] font-semibold">
                      {badgeCount}
                    </span>
                  </div>
                )}

              <span className="text-[16px] leading-[20.11px]">{label}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default TabNavigator;
