import { createContext, useContext } from "react";
interface MyContextValue {
  token: any;
  selectedOrg: any;
  selectedProject: any;
  selectedEnv: any;
  orgLoading: any;
  projectLoading: any;
  EnvLoading: any;
  environmentOption: any;
}

export const MyContext = createContext<MyContextValue | undefined>(undefined);
export const useMyContext = () => {
  return useContext(MyContext);
};
