import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VscClose } from "react-icons/vsc";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { DatePickerFormat } from "../../helper/constants";
import { useMyContext } from "../../myContext";
import "../../style/date-picker.css";
import { SelectStyle } from "../../utility/constant";
import Loader from "../Loader";
import CustomSelectIcon from "../custom-select/SelectIcon";
import {
  formatScheduleTime,
  isFutureTime,
  separateDateAndTime,
} from "../../helper/utility-functions";
enum TestType {
  EPIC = "epic",
  USER_STORY = "story",
  TEST_CASE = "test_case",
  PROJECT = "project",
}

const ScheduleTestPopup = ({
  modalIsOpen,
  handleCloseModal,
  projectId,
  getTestCyclesData,
  initialData,
  modalType,
  scheduleDataLoaidng,
}: any) => {
  const base_url = process.env.REACT_APP_API_URL;
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { selectedEnv }: any = useMyContext();

  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: "24px",
      borderRadius: "16px",
      width: "408px",
      // minHeight: "490px",
      // maxHeight: "714px",
      overflow: "visible",
    },
  };

  interface Option {
    value: string;
    label: string;
  }
  enum RepeatFrequency {
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
  }
  const repeatOption: any = [
    { value: RepeatFrequency.DAILY, label: "Daily" },
    { value: RepeatFrequency.WEEKLY, label: "Weekly" },
    { value: RepeatFrequency.MONTHLY, label: "Monthly" },
  ];
  const [isAM, setIsAM] = useState(true);

  const handleAMChange = () => {
    setIsAM(true);
  };

  const handlePMChange = () => {
    setIsAM(false);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [selectedRepeat, setSelectedRepeatItem] = useState<Option>(
    repeatOption[0]
  );
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const handleTimeChange: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void = (time: any, e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setSelectedTime(time as Date);
    // setSelectedTime(time);
    setTimeout(() => {}, 50);
    // setShowTimePicker(false);
  };
  const handleRepeatChange = (selectedRepeat: any) => {
    setSelectedRepeatItem(selectedRepeat);
  };

  const typeOptions: Option[] = [
    { value: TestType.EPIC, label: "Epic" },
    { value: TestType.USER_STORY, label: "User Story" },
    { value: TestType.TEST_CASE, label: "Test Case" },
    { value: TestType.PROJECT, label: "All Test Case" },
  ];

  const [relatedOptions, setRelatedOptions] = useState<any>([]);
  const [selectedType, setSelectedType] = useState<Option | null | any>(null);
  const [selectedRelatedOption, setSelectedRelatedOption] = useState<
    Option | null | any
  >(null);

  const getEpicsData = () => {
    setLoading(true);
    axios
      .get(`${base_url}/api/v1/project/${projectId?.value}/epics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const options = res?.data?.map((item: any) => {
            return {
              label: item.summary,
              value: item.id,
              Data: "Epic",
            };
          });
          setRelatedOptions(options);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getStoriesList = () => {
    setLoading(true);
    axios
      .get(`${base_url}/api/v1/project/${projectId?.value}/stories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const options = res?.data?.map((item: any) => {
            return {
              label: item.summary,
              value: item.id,
            };
          });
          setRelatedOptions(options);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getTestCaseData = () => {
    setLoading(true);
    axios
      .get(`${base_url}/api/v1/project/${projectId?.value}/testcases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const options = res?.data?.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
              Data: "TestCase",
            };
          });
          setRelatedOptions(options);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const handleTypeChange = (selectedOption: any) => {
    setSelectedType(selectedOption);
    if (selectedOption?.value === TestType.EPIC) {
      getEpicsData();
    }
    if (selectedOption?.value === TestType.TEST_CASE) {
      getTestCaseData();
    } else {
      if (selectedOption?.value === TestType.USER_STORY) {
        getStoriesList();
      } else {
        setRelatedOptions([]);
      }
      setSelectedRelatedOption(null);
    }
  };
  const handleSave = async () => {
    try {
      if (
        selectedType?.value !== TestType.PROJECT &&
        (!selectedRelatedOption?.value || !selectedType?.value)
      ) {
        return toast.error("Please fill all the fields");
      }

      const combinedDateTime = formatScheduleTime(startDate, selectedTime);
      const combinedDateTimeGMT = combinedDateTime?.utc();

      const payload = {
        object_id:
          selectedType?.value === TestType.PROJECT
            ? projectId.value?.toString()
            : selectedRelatedOption?.value?.toString(),
        object_type: selectedType?.value,
        schedule_time: combinedDateTimeGMT.format(),
        repeat_type: selectedRepeat?.value,
        environment_id: selectedEnv?.value,
      };
      setLoading(true);
      if (modalType === "EDIT") {
        const res: any = await axios.put(
          `${base_url}/api/v1/schedule-test/${initialData?.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 200) {
          setLoading(false);
          toast.success("Schedule updated successfully ");
          getTestCyclesData();
          handleCloseModal();
        }
    
        
        return;
      }
      const res: any = await axios.post(
        `${base_url}/api/v1/schedule-test/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setLoading(false);
        toast.success("Schedule added successfully ");
        getTestCyclesData();
        handleCloseModal();
      }
    } catch (err: any) {
      setLoading(false);
      toast.error(err.response?.data?.schema_errors?.schedule_time[0])

      toast.error(err?.response?.data?.message);
    }
  };

  const closeDatePicker = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.preventDefault();
      e?.stopPropagation();
      // setShowDatePicker(false);
    },
    []
  );
  const closeTimePicker = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.preventDefault();
      e?.stopPropagation();
      // setShowTimePicker(false);
    },
    []
  );
  const handleCloseTimePicker = () => {};
  const filterTime = useCallback((time: Date, range: "AM" | "PM" = "PM") => {
    const offset = range === "AM" ? 0 : 12;
    let start = new Date();
    start.setHours(0 + offset, 0, 0, 0);
    let end = new Date(start);
    end.setHours(12 + offset, 0, 0, 0);
    return start <= time && time <= end;
  }, []);

  useEffect(() => {
    if (initialData?.object_type === TestType.EPIC) {
      getEpicsData();
    } else if (initialData?.object_type === TestType.TEST_CASE) {
      getTestCaseData();
    } else if (initialData?.object_type === TestType.USER_STORY) {
      getStoriesList();
    } else {
      setRelatedOptions([]);

      setSelectedRelatedOption(null);
    }
  }, [initialData]);
  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setToken(token);
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently]);
  useEffect(() => {
    if (initialData && relatedOptions) {
      const combinedDateTime: any = initialData?.schedule_time
        ? new Date(initialData?.schedule_time)
        : "";
      const isAMValue = combinedDateTime?.getHours?.() < 12;

      setIsAM(isAMValue);
      // setSelectedTime();
      // handleTimeChange(combinedDateTime, undefined);
      setStartDate(combinedDateTime);
      if (initialData?.repeat_type === "daily") {
        setSelectedRepeatItem({ value: RepeatFrequency.DAILY, label: "Daily" });
      } else if (initialData?.repeat_type === "weekly") {
        setSelectedRepeatItem({
          value: RepeatFrequency.WEEKLY,
          label: "Weekly",
        });
      } else if (initialData?.repeat_type === "monthly") {
        setSelectedRepeatItem({
          value: RepeatFrequency.MONTHLY,
          label: "Monthly",
        });
      } else {
        setSelectedRepeatItem({
          value: "",
          label: "",
        });
      }
      if (initialData?.object_type === TestType.EPIC) {
        setSelectedType({ value: TestType.EPIC, label: "Epic" });
      } else if (initialData?.object_type === TestType.PROJECT) {
        setSelectedType({ value: TestType.PROJECT, label: "All Test Case" });
      } else if (initialData?.object_type === TestType.USER_STORY) {
        setSelectedType({ value: TestType.USER_STORY, label: "User Story" });
      } else if (initialData?.object_type === TestType.TEST_CASE) {
        setSelectedType({ value: TestType.TEST_CASE, label: "Test Case" });
      } else {
        setSelectedType({ value: "", label: "" });
      }
    }
  }, [initialData]);
  useEffect(() => {
    if (relatedOptions) {
      const relatedOption = relatedOptions?.find(
        (option: Option) => option?.value === initialData?.object_id
      );
      setSelectedRelatedOption(relatedOption);
    }
  }, [relatedOptions]);
  // useEffect(()=>{
  //   console.log(selectedTime,"selectedType")
  //   console.log(new Date(initialData?.schedule_time),"selectedType")

  // },[selectedTime,initialData])
  return (
    <Modal
      style={customStyles}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <div className="flex flex-col gap-[16px]">
        <div className="flex justify-between items-center">
          <p className="text-[20px] font-bold font-montserrat not-italic leading-[24px] text-[#181B20]">
            Schedule test
          </p>
          <VscClose
            size={22}
            className="text-2xl cursor-pointer text-[#1B5299]"
            onClick={() => {
              handleCloseModal();
            }}
            color="black"
          />
        </div>
        {scheduleDataLoaidng ? (
          <div className="flex justify-center items-center h-[287px]">
            <Loader size={50} color={"#224BF8"} loading={scheduleDataLoaidng} />
          </div>
        ) : (
          <div className="w-[360px] grid gap-[16px]">
            <div
              onClick={() => setShowTimePicker(!showTimePicker)}
              className="relative flex items-center w-[333px]"
            >
              <div
                className="flex "
                // onClick={() => setShowTimePicker(!showTimePicker)}
              >
                <label className="absolute -top-2 z-[1] left-5 text-[12px] text-gray-400 bg-white px-1">
                  Time:
                </label>
                <DatePicker
                  icon={
                    <div className="flex">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="20px / Icons / Clock">
                          <path
                            id="Color"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.3334 9.16675H10.8334V6.66675C10.8334 6.20591 10.4601 5.83341 10.0001 5.83341C9.54008 5.83341 9.16675 6.20591 9.16675 6.66675V10.0001C9.16675 10.4609 9.54008 10.8334 10.0001 10.8334H13.3334C13.7942 10.8334 14.1667 10.4609 14.1667 10.0001C14.1667 9.53925 13.7942 9.16675 13.3334 9.16675ZM10.0001 16.6667C6.32425 16.6667 3.33341 13.6759 3.33341 10.0001C3.33341 6.32425 6.32425 3.33341 10.0001 3.33341C13.6759 3.33341 16.6667 6.32425 16.6667 10.0001C16.6667 13.6759 13.6759 16.6667 10.0001 16.6667ZM10.0001 1.66675C5.40508 1.66675 1.66675 5.40508 1.66675 10.0001C1.66675 14.5951 5.40508 18.3334 10.0001 18.3334C14.5951 18.3334 18.3334 14.5951 18.3334 10.0001C18.3334 5.40508 14.5951 1.66675 10.0001 1.66675Z"
                            fill="#919EAB"
                          />
                        </g>
                      </svg>
                      <div
                        // onClick={() => setShowTimePicker(!showTimePicker)}
                        className="absolute left-[119px] cursor-pointer h-5 w-3"
                      >
                        <img
                          // onClick={() => setShowTimePicker(!showTimePicker)}
                          alt=""
                          src={
                            showTimePicker
                              ? "/assets/icons/Arrow/Chevron/up.svg"
                              : "/assets/icons/Arrow/Chevron/down.svg"
                          }
                          className="h-full w-full"
                        />
                      </div>
                    </div>
                  }
                  showIcon
                  open={showTimePicker}
                  className="dateTimePickerValue"
                  wrapperClassName="time-picker"
                  timeClassName={() => "time-picker-option"}
                  selected={selectedTime}
                  onChange={handleTimeChange}
                  onClickOutside={closeTimePicker}
                  onCalendarClose={closeTimePicker}
                  showTimeSelect
                  timeFormat={DatePickerFormat.TIME_12}
                  filterTime={(dt) => filterTime(dt, isAM ? "AM" : "PM")}
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat={DatePickerFormat.TIME_12O}
                  timeCaption="Time"
                />
              </div>
              <div className="flex gap-[10px] items-center ml-[24px]">
                <input
                  type="radio"
                  name="time"
                  className="w-[24px] h-[24px] text-[#004CFF]"
                  // checked={checkAM_PM}
                  checked={isAM}
                  onChange={handleAMChange}
                />
                <label className="text-[16px] leading-normal font-semibold not-italic">
                  AM
                </label>
              </div>
              <div className="flex gap-[10px] items-center ml-[16px]">
                {/* @ts-ignore */}
                <input
                  type="radio"
                  name="time"
                  className="w-[24px] h-[24px] text-[#004CFF]"
                  // checked={!checkAM_PM}
                  checked={!isAM}
                  onChange={handlePMChange}
                />
                <label className="text-[16px] leading-normal font-semibold not-italic">
                  PM
                </label>
              </div>
            </div>

            <div
              className="relative flex gap-[24px] items-center"
              onClick={() => {
                setShowDatePicker(!showDatePicker);
              }}
            >
              <label className="absolute -top-2 z-[1] left-5 text-[12px] text-gray-400 bg-white px-1">
                Date:
              </label>
              <DatePicker
                showIcon
                dateFormat={DatePickerFormat.DATE}
                className="dateTimePickerValue"
                wrapperClassName="date-picker"
                minDate={new Date()}
                selected={startDate}
                onChange={(date, e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  setStartDate(date as Date);
                  setShowDatePicker(false);
                  handleCloseTimePicker();
                }}
                open={showDatePicker}
                onClickOutside={closeDatePicker}
                onCalendarClose={closeDatePicker}
                icon={
                  <div className="flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.83333 13.3334C5.83333 12.8751 6.20833 12.5001 6.66667 12.5001C7.125 12.5001 7.5 12.8751 7.5 13.3334C7.5 13.7917 7.125 14.1667 6.66667 14.1667C6.20833 14.1667 5.83333 13.7917 5.83333 13.3334ZM10 12.5001H13.3333C13.7917 12.5001 14.1667 12.8751 14.1667 13.3334C14.1667 13.7917 13.7917 14.1667 13.3333 14.1667H10C9.54167 14.1667 9.16667 13.7917 9.16667 13.3334C9.16667 12.8751 9.54167 12.5001 10 12.5001ZM15 16.6667H5C4.54083 16.6667 4.16667 16.2926 4.16667 15.8334V10.8334H15.8333V15.8334C15.8333 16.2926 15.4592 16.6667 15 16.6667ZM5 5.00008H5.83333V5.83342C5.83333 6.29175 6.20833 6.66675 6.66667 6.66675C7.125 6.66675 7.5 6.29175 7.5 5.83342V5.00008H12.5V5.83342C12.5 6.29175 12.875 6.66675 13.3333 6.66675C13.7917 6.66675 14.1667 6.29175 14.1667 5.83342V5.00008H15C15.4592 5.00008 15.8333 5.37425 15.8333 5.83342V9.16675H4.16667V5.83342C4.16667 5.37425 4.54083 5.00008 5 5.00008ZM15 3.33341H14.1667V2.50008C14.1667 2.04175 13.7917 1.66675 13.3333 1.66675C12.875 1.66675 12.5 2.04175 12.5 2.50008V3.33341H7.5V2.50008C7.5 2.04175 7.125 1.66675 6.66667 1.66675C6.20833 1.66675 5.83333 2.04175 5.83333 2.50008V3.33341H5C3.62167 3.33341 2.5 4.45508 2.5 5.83342V15.8334C2.5 17.2117 3.62167 18.3334 5 18.3334H15C16.3783 18.3334 17.5 17.2117 17.5 15.8334V5.83342C17.5 4.45508 16.3783 3.33341 15 3.33341Z"
                        fill="#919EAB"
                      />
                    </svg>
                    <div className="absolute left-[302px] cursor-pointer h-5 w-3">
                      <img
                        alt=""
                        src={
                          showDatePicker
                            ? "/assets/icons/Arrow/Chevron/up.svg"
                            : "/assets/icons/Arrow/Chevron/down.svg"
                        }
                        className="h-full w-full"
                      />
                    </div>
                  </div>
                }
              ></DatePicker>
            </div>
            <div className="relative flex gap-[24px] items-center">
              <label className="absolute -top-2 z-[1] left-5 text-[12px] text-gray-400 bg-white px-1">
                Repeat:
              </label>
              <Select
                value={selectedRepeat}
                onChange={handleRepeatChange}
                options={repeatOption}
                minMenuHeight={10}
                styles={SelectStyle}
                isSearchable={true}
                components={{ IndicatorsContainer: CustomSelectIcon }}
              />
            </div>
            <div className="flex gap-[24px] items-center">
              <Select
                options={typeOptions}
                onChange={handleTypeChange}
                placeholder="Select Type"
                value={selectedType}
                minMenuHeight={10}
                styles={SelectStyle}
                components={{ IndicatorsContainer: CustomSelectIcon }}
              />
            </div>
            <div className="flex gap-[24px] items-center">
              {[
                TestType.EPIC,
                TestType.TEST_CASE,
                TestType.USER_STORY,
              ].includes(selectedType?.value) && (
                <Select
                  minMenuHeight={10}
                  styles={SelectStyle}
                  options={relatedOptions}
                  onChange={(selectedOption) =>
                    setSelectedRelatedOption(selectedOption)
                  }
                  placeholder={`Select ${selectedType.value}`}
                  value={selectedRelatedOption}
                  isLoading={loading}
                  isSearchable={true}
                  components={{ IndicatorsContainer: CustomSelectIcon }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-3 mt-[24px]">
        <button
          // disabled={disable}
          className="border w-[174px] h-[46px] rounded-[8px] text-[16px] font-semibold px-[82px] bg-white text-[#919EAB] outline-none"
          onClick={() => {
            handleCloseModal();
          }}
        >
          <span className="flex justify-center">Cancel</span>
        </button>
        <button
          onClick={() => handleSave()}
          className="border w-[174px] h-[46px]  rounded-[8px] px-[82px] bg-[#004CFF]  outline-none scheduleTestButtonShadow"
        >
          {loading ? (
            <div className="flex justify-center items-center h-[10px]">
              <Loader size={40} color={"white"} loading={loading} />
            </div>
          ) : (
            <span className="flex justify-center text-[16px] text-white font-bold">
              Save
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default ScheduleTestPopup;
