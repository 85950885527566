import React, { useState } from "react";
import Modal from "react-modal";
import { VscClose } from "react-icons/vsc";
import classNames from "classnames";
import Loader from "../Loader";

const DeleteSchedule = ({
  modalIsOpenRemoveUser,
  handleCloseRemoveUserModalPopup,
  scheduleId,
  deleteSchedule,
  loading,
  name
}: any) => {
  const customStyles: any = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      width: "368px",
      height: "190px",
      padding: "24px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 100,
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={modalIsOpenRemoveUser}
      onRequestClose={handleCloseRemoveUserModalPopup}
      shouldCloseOnOverlayClick={false}
    >
      <div className="grid gap-[8px]">
        <div className="flex justify-center">
          <div className="grid gap-[4px]">
            <p className="text-[20px] text-[#181B20] font-bold font-montserrat not-italic leading-[24px]">
              Delete Schedule
            </p>
          </div>
        </div>
        <div className="h-[40px] leading-[20px] font-normal text-[14px] text-center not-italic text-[#A3A4A6]">
          Are you sure you want to delete schedule for {name}?
        </div>
        <div className="flex gap-3 mt-[14px]">
          <button
            onClick={handleCloseRemoveUserModalPopup}
            className="border w-[154PX] h-[46px] rounded-[8px] text-[16px] font-semibold py-[6px] bg-[#ffffff] text-[#919EAB] outline-none"
          >
            Cancel
          </button>
          <button
          onClick={()=>deleteSchedule(scheduleId)}
            className={classNames(
              "border w-[154px] rounded-[8px] text-[16px] h-[46px] font-semibold bg-[#EB175C] text-[#FFFFFF] outline-none scheduleDeleteButton"
            )}
          >
            {
              loading ?  <div className="flex justify-center items-center h-[10px]">
              <Loader size={40} color={"white"} loading={loading} />
            </div> : " Delete"
           
            }
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSchedule;
