// Auth0Provider.js
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import auth0Config from './Auth0-config';

const Auth0ProviderWithHistory = ({ children }) => (
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    authorizationParams={{
      redirect_uri: auth0Config.redirectUri,
      audience: process.env.REACT_APP_AUDIENCE,
      scope: process.env.REACT_APP_SCOPE,
      grantType:process.env.REACT_APP_GRANT_TYPE
    }}
  >
    {children}
  </Auth0Provider>
);

export default Auth0ProviderWithHistory;