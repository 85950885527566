import axios from "axios";
import { ArcElement, Chart } from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AgGrid from "../commonComponents/grid";
import { DateTimeFormat } from "../helper/constants";
import { toDefaultDateFormat } from "../helper/utility-functions";
import dotEllipse from "../images/Ellipse.svg";
import notTested from "../images/notTested.svg";
import failDot from "../images/fail.svg";
import { useMyContext } from "../myContext";
import DefectTable from "./DefectTable";
import Graph from "./Graph";
import TabNavigator, { TabNavigatorTabType } from "./TabNavigator";

Chart.register(ArcElement);

const Defect = () => {
  const { token, selectedOrg, selectedEnv, selectedProject }: any =
    useMyContext();
  const base_url = process.env.REACT_APP_API_URL;
  const defect_raised = process.env.REACT_APP_DEFECTS_RAISED;

  const [epicDefectData, setEpicDefectData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tab, setTab] = React.useState<any>(1);
  const [defectRaisedData, setDefectRaisedData] = React.useState<any[]>([]);
  const [graphData, setGraphData] = useState<any>();
  const [gridLoading, setGridLoading] = useState<Boolean>(false);
  const [defectRaisedParamsList, setDefectRaisedParamsList] = useState<any>({
    page: 1,
    limit: 10,
    forcePage: 0,
  });
  const tabs: TabNavigatorTabType[] = useMemo(
    () => [
      {
        id: "",
        label: "Test Cycles",
        link: "/",
      },
      {
        id: "",
        label: "Defect Management",
        link: "/defect-management",
        active: true,
      },
      {
        id: "",
        label: "Testing Que",
        link: "/testing-queue",
        hideCredits: true,
      },
      {
        id: "",
        label: "Testing Server",
        link: "/testing-server",
      },
    ],
    []
  );

  const columnDefs = useMemo<any>(
    () => [
      {
        headerName: "AC",
        field: "new_acceptance_criteria",
        maxWidth: 320,
        minWidth: 320,
        width: 320,
        headerComponent: (props: any) => {
          return (
            <div className="text-[16px] leading-[20.11px] font-semibold flex items-center">
              {props.displayName}
            </div>
          );
        },
        cellClass: (params: any) =>
          params?.value !== "-" &&
          params?.data.new_acceptance_criteria_URL?.trim() !== ""
            ? "underline text-[#1B0076] cursor-pointer"
            : "text-[black] cursor-pointer",

        cellRenderer: (params: any) => {
          return (
            <div
              className="truncate w-full flex justify-start"
              title={params?.value}
            >
              {/* @ts-ignore */}
              <Link
                to={`${defect_raised}${params?.data?.new_acceptance_criteria_URL}`}
              >
                {params?.value ? params?.value : "-"}
              </Link>
            </div>
          );
        },
      },
      {
        headerName: "Related User story",
        field: "new_user_story",
        minWidth: 240,
        maxWidth: 240,
        width: 240,
        sortable: true,
        sortingOrder: ["asc", "desc"], // Set the sorting order here
        headerComponent: (props: any) => {
          return (
            <div className="text-[16px] font-semibold flex items-center leading-[20.11px]">
              {props.displayName}
            </div>
          );
        },
        cellRenderer: (params: any) => {
          return (
            <div
              className="flex  w-full truncate items-center"
              title={params.value}
            >
              {params.value ? params.value : "-"}
            </div>
          );
        },
      },
      {
        headerName: "Epic",
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        sortable: true,
        field: "new_epic",
        sortingOrder: ["asc", "desc"], // Set the sorting order here
        headerComponent: (props: any) => {
          return (
            <div className="text-[16px] leading-[20.11px] font-semibold flex items-center">
              {props.displayName}
            </div>
          );
        },
        cellRenderer: (params: any) => {
          return (
            <div
              className="flex  w-full truncate items-center"
              title={params.value}
            >
              {params.value ? params.value : "-"}
            </div>
          );
        },
      },
      {
        headerName: "Last Test Run",
        field: "new_last_test_run",
        minWidth: 180,
        width: 180,
        sortable: true,
        sortingOrder: ["asc", "desc"], // Set the sorting order here

        maxWidth: 180,
        cellRenderer: (params: any) => {
          return (
            <div
              className="flex  w-full items-center justify-center truncate"
              title={params.value}
            >
              {params.value ? params.value : "-"}
            </div>
          );
        },
      },
      {
        headerName: "Date Created",
        field: "new_date_created",
        minWidth: 130,
        maxWidth: 130,
        sortingOrder: ["asc", "desc"], // Set the sorting order here

        sortable: true,
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div
              className="flex  w-full items-center justify-center truncate"
              title={params.value}
            >
              {params.value ? params.value : "-"}
            </div>
          );
        },
      },
      {
        headerName: "Project Status",
        field: "new_jira_epic_status",
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        sortingOrder: ["asc", "desc"], // Set the sorting order here

        sortable: true,
        cellRenderer: (params: any) => {
          return (
            <div
              className="flex  w-full items-center justify-center truncate"
              title={params.value}
            >
              {params.value ? params.value : "-"}
            </div>
          );
        },
      },
      {
        headerName: "Defect Ticket",
        field: "new_defect_ticket",
        minWidth: 130,
        maxWidth: 130,
        sortingOrder: ["asc", "desc"], // Set the sorting order here

        sortable: true,
        width: 130,
        cellRenderer: (params: any) => {
          if (
            params?.value &&
            (params?.value.startsWith("https://") ||
              params?.value.startsWith("http://"))
          ) {
            return (
              <Link
                to={params?.value}
                title={params?.value}
                className="underline flex  w-full items-center   truncate text-[#1B0076] cursor-pointer"
              >
                {params?.value}
              </Link>
            );
          } else {
            return (
              <p
                title={params?.value}
                className="text-[black] flex  w-full items-center  truncate cursor-pointer"
              >
                {params.value ? params?.value : "-"}
              </p>
            );
          }
        },
      },
      {
        headerName: "Status",
        minWidth: 100,
        width: 100,
        sortable: true,
        sortingOrder: ["asc", "desc"], // Set the sorting order here

        maxWidth: 100,
        field: "new_Status",
        cellRenderer: ({ data }: any) => {
          return (
            <>
              {data.new_status === "PASS" ? (
                <p className="bg-[#EAFBEB] text-[#26D237] rounded-[6px] py-[2px] px-[8px] flex items-center justify-center w-[50px] h-[24px]">
                  <div className="flex items-center gap-[4px]">
                    <div>
                      <img src={dotEllipse} alt="dotEllipse" />
                    </div>
                    <span
                      title="Pass"
                      className="text-[#26D237] font-semibold text-[12px] leading-[20px]"
                    >
                      Pass
                    </span>
                  </div>
                </p>
              ) : data.new_status === "FAIL" ? (
                <p className="bg-[#FDE8EF] text-[#CD0043] rounded-[6px] py-[2px] px-[8px] flex items-center justify-center w-[50px] h-[24px]">
                  <div className="flex items-center gap-[4px]">
                    <div>
                      <img src={failDot} alt="dotEllipse" />
                    </div>
                    <span
                      title="Fail"
                      className="text-[#CD0043] font-semibold text-[12px] leading-[20px]"
                    >
                      Fail
                    </span>
                  </div>
                </p>
              ) : data.new_status === "Not Tested" ? (
                <p className="bg-textInQue text-textProgressStarted  rounded-[6px] py-[2px] px-[8px] truncate flex items-center justify-center w-[85px] h-[24px]">
                  <div className="flex items-center gap-[4px] w-full">
                    <div>
                      <img
                        style={{ borderRadius: "50px", maxWidth: "none" }}
                        className="w-[6px] h-[6px]"
                        src={notTested}
                        alt="dotEllipse"
                      />
                    </div>

                    <span
                      title={data.new_status}
                      className=" text-textProgressStarted truncate font-semibold text-[12px] leading-[20px]"
                    >
                      {data.new_status}
                    </span>
                  </div>
                </p>
              ) : data.new_status === "Cancelled" ? (
                <p className="bg-cancelled text-textCancelled  rounded-[6px] py-[2px] px-[8px] truncate flex items-center justify-center w-[80px] h-[24px]">
                  <div className="flex items-center gap-[4px] w-full">
                    <div>
                      <img
                        style={{ borderRadius: "50px", maxWidth: "none" }}
                        className="w-[6px] h-[6px]"
                        src={notTested}
                        alt="dotEllipse"
                      />
                    </div>

                    <span
                      title={data.new_status}
                      className=" text-textProgressStarted truncate font-semibold text-[12px] leading-[20px]"
                    >
                      {data.new_status}
                    </span>
                  </div>
                </p>
              ) : (
                <span
                  title={data.new_status}
                  className="flex  w-full items-center justify-center truncate"
                >
                  -
                </span>
              )}
            </>
          );
        },
      },
    ],
    []
  );
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
    };
  }, []);

  const nextHandler = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setTotalPages(totalPages + 1);
    }
  };

  const prevHandler = () => {
    if (page !== 1) {
      setPage(page - 1);
      setTotalPages(totalPages - 1);
    }
  };

  const centerTextPlugin1: any = {
    id: "centerTextPlugin1",
    beforeDraw: (chart: any) => {
      if (chart.options.circularBorder) {
        const ctx = chart.ctx;
        const canvas = chart.canvas;
        const center = {
          x: canvas.width / 2,
          y: canvas.height / 2,
        };

        const radius = chart.innerRadius;
        const borderThickness = 5; // You can adjust the border thickness as needed

        ctx.beginPath();
        ctx.arc(
          center.x,
          center.y,
          radius + borderThickness / 2,
          0,
          2 * Math.PI
        );
        ctx.strokeStyle = "rgba(0, 0, 0, 0.1)"; // Change the color as needed
        ctx.lineWidth = borderThickness;
        ctx.stroke();
      }
    },
    afterDraw: (chart: any) => {
      const width = chart.width;
      const height = chart.height;
      const fontSize = 16;
      const ctx = chart.ctx;
      const lineHeight = fontSize * 1.2;
    },
  };
  const getDefectEpics = async () => {
    try {
      const envQuery = selectedEnv
        ? `&environment_id=${selectedEnv?.value}`
        : "";
      setLoading(true);
      const res = await axios.get(
        `${base_url}/api/v1/testcase/defects_epics_overview?organization_id=${selectedOrg?.value}&project_id=${selectedProject?.value}${envQuery}&page=1&size=10000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setEpicDefectData(res.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const getDefectRaisedList = async () => {
    try {
      const envQuery = selectedEnv
        ? `&environment_id=${selectedEnv?.value}`
        : `&environment_id=${0}`;
      setGridLoading(true);
      const res = await axios.get(
        `${base_url}/api/v1/testcase/defects_raised?organization_id=${selectedOrg?.value}&project_id=${selectedProject?.value}${envQuery}&page=${defectRaisedParamsList?.page}&size=${defectRaisedParamsList.limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        let result = res.data.data;
        result = result?.map((item: any) => ({
          new_acceptance_criteria_URL: item?.acceptance_criteria_url,
          new_name: item?.name ? item?.name : "-",
          new_user_story: item?.user_story ? item?.user_story : "-",
          new_acceptance_criteria: item?.acceptance_criteria
            ? item?.acceptance_criteria
            : "-",
          new_epic: item?.epic ? item?.epic : "-",
          new_last_test_run: toDefaultDateFormat(
            item?.last_test_run,
            DateTimeFormat.DATE_TIME,
            "-"
          ),
          new_date_created: toDefaultDateFormat(
            item?.date_created,
            DateTimeFormat.DATE,
            "-"
          ),
          new_jira_epic_status: item?.jira_epic_status
            ? item?.jira_epic_status
            : "-",
          new_defect_ticket: item?.defect_ticket ? item?.defect_ticket : "-",
          new_status: item?.status ? item?.status : "-",
        }));

        setDefectRaisedData(result);
        setTotal(res?.data?.total);
        setGridLoading(false);
      }
    } catch (err) {
      setDefectRaisedData([]);
      setGridLoading(false);

      console.log(err);
    }
  };

  const getExecutionResult = async () => {
    try {
      const envQuery = selectedEnv
        ? `&environment_id=${selectedEnv?.value}`
        : "";
      setLoading(true);
      const res = await axios.get(
        `${base_url}/api/v1/testcase/test_execution_result?organization_id=${selectedOrg.value}&project_id=${selectedProject.value}${envQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setGraphData(res.data);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getExecutionResult()
      .then()
      .catch((err: any) => console.log(err));
  }, [selectedProject, selectedEnv]);
  useEffect(() => {
    getDefectRaisedList()
      .then()
      .catch((err: any) => console.log(err));
  }, [selectedOrg, selectedProject, selectedEnv, defectRaisedParamsList]);

  useEffect(() => {
    getDefectEpics();
  }, [selectedProject, selectedEnv]);
  const staticRowData = [
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
    {
      new_acceptance_criteria: "Criteria 2",
      new_acceptance_criteria_URL: "https://example.com/criteria2",
      new_user_story: "User Story 2",
      new_epic: "Epic 2",
      new_last_test_run: "2023-02-01",
      new_date_created: "2023-02-02",
      new_jira_epic_status: "Done",
      new_defect_ticket: "https://example.com/defect2",
      new_Status: "FAIL",
    },
  ];
  return (
    <>
      <div className="grid gap-[16px] !h-[436px]">
        <div className=" !h-[436px]  bg-white gap-6   rounded-[16px]">
          <div className="grid items-stretch">
            <TabNavigator tabs={tabs} flex />
            <div className="flex gap-[40px] mt-[24px]">
              <Graph loading={loading} data={graphData} />
              <DefectTable loading={loading} epicDefectData={epicDefectData} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[16px] ml-[-24px] w-[1424px] bg-white p-[24px] rounded-[16px]">
        <div className="flex mb-4 justify-between">
          <p className="text-[18px] font-montserrat font-[700] leading-[24.3px]">
            Defects Raised
          </p>
        </div>

        <div className="tableBg">
          <AgGrid
            className="!font-source-sans-pro"
            columnDefs={columnDefs}
            rowData={defectRaisedData}
            // rowData={staticRowData}
            // height="200px"
            domLayout={"autoHeight"}
            perPage={perPage}
            pageCount={"1"}
            total={total}
            itemsPerPage={defectRaisedParamsList?.limit}
            handlePageClick={(page: any) => {
              setDefectRaisedParamsList((prev: any) => ({
                ...prev,
                page: page?.selected + 1,
                forcePage: page?.selected,
              }));
            }}
            gridLoading={gridLoading}
            forcePage={defectRaisedParamsList?.forcePage}
            page={defectRaisedParamsList.page}
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            totalPages={totalPages}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>
    </>
  );
};
export default Defect;
