import Defect from "../components/Defect";

const DefectManagement = () => {

  return (
    <>
      <div className="flex flex-col justify-between">
        <div className="flex justify-between items-center w-full">
          <div className="w-full max-w-2xl"></div>
        </div>
        <Defect />
      </div>
    </>
  );
};
export default DefectManagement;
