import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
export type EntityStatus = Record<
  "epic" | "story" | "test_step" | "project" | "test_case",
  Record<string, "In Progress" | null>
>;
export interface HandleSocketEvent {
  event: string;
  data: { id: string; status: "In Progress" | null };
}

export interface SocketContextProps {
  entityStatus: EntityStatus;
  handleSocketEvent?: (
    event: keyof EntityStatus,
    data: { id: string; status: "In Progress" | null }
  ) => void;
}
const defaultValue: SocketContextProps = {
  entityStatus: {
    epic: {},
    story: {},
    test_step: {},
    project: {},
    test_case: {},
  },
};
export const SocketContext = createContext<SocketContextProps>(defaultValue);

export const SocketProvider = (props: { children: any }) => {
  const rawData = useRef(defaultValue.entityStatus);
  const [entityStatus, setEntityStatus] = useState<EntityStatus>({
    epic: {},
    story: {},
    test_step: {},
    project: {},
    test_case: {},
  });
  useEffect(() => {
    const storedEntityStatus = JSON.parse(
      localStorage.getItem("entityStatus")!
    );
    if (storedEntityStatus) {
      rawData.current = storedEntityStatus;
      setEntityStatus(rawData.current);
    }
  }, []);
  const handleSocketEvent = useCallback<
    Required<SocketContextProps>["handleSocketEvent"]
  >((event, data) => {
    let raw_data = rawData.current;
    switch (event) {
      case "test_case":
        if (data.status === "In Progress")
          raw_data.test_case[data.id] = data.status;
        else delete raw_data.test_case[data.id];
        break;
      case "story":
        if (data.status === "In Progress")
          raw_data.story[data.id] = data.status;
        else delete raw_data.story[data.id];
        break;
      case "epic":
        if (data.status === "In Progress") raw_data.epic[data.id] = data.status;
        else delete raw_data.epic[data.id];
        break;
      case "project":
        if (data.status === "In Progress")
          raw_data.project[data.id] = data.status;
        else delete raw_data.project[data.id];
        break;
      case "test_step":
        if (data.status === "In Progress")
          raw_data.test_step[data.id] = data.status;
        else delete raw_data.test_step[data.id];
        break;
      default:
        return null;
    }
    setEntityStatus({ ...raw_data });
  }, []);

  useEffect(() => {
    localStorage.setItem("entityStatus", JSON.stringify(entityStatus));
  }, [entityStatus]);

  return (
    <SocketContext.Provider
      value={{
        entityStatus,
        handleSocketEvent,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
