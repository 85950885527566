import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7713 10.8282L8.94283 7.99978L11.7713 5.17135C12.0319 4.91066 12.0315 4.48876 11.7713 4.22854C11.511 3.96833 11.0891 3.96785 10.8284 4.22854L8.00002 7.05697L5.17159 4.22854C4.91138 3.96833 4.48947 3.96785 4.22879 4.22854C3.9681 4.48923 3.96857 4.91114 4.22879 5.17135L7.05721 7.99978L4.22879 10.8282C3.9681 11.0889 3.96857 11.5108 4.22879 11.771C4.489 12.0312 4.91091 12.0317 5.17159 11.771L8.00002 8.94259L10.8284 11.771C11.0887 12.0312 11.5106 12.0317 11.7713 11.771C12.0319 11.5103 12.0315 11.0884 11.7713 10.8282Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
