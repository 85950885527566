import { HiDotsVertical } from "react-icons/hi";
import Popup from "reactjs-popup";
import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { IoPauseCircleOutline } from "react-icons/io5";
import axios from "axios";
import { useMyContext } from "../../myContext";
import { checkStatus } from "../../helper/utility-functions";
import { SocketContextProps, useSocket } from "../context/socketcontext";

interface Props {
  actionsData: {
    onClick: (rowData: any, ...params: any) => any; // Add rowData parameter
    icon?: any;
    label: string;
    disabled?: boolean;
    hide?: boolean;
  }[];
  gridParams: any;
  [key: string]: any;
}

const TestCycleActionPopup = ({ actionsData, gridParams, actionsInprogress }: Props) => {
  const { token }: any = useMyContext();
  const base_url = process.env.REACT_APP_API_URL;
  const tooltipRef: any = useRef(null);
  const [runCycleActive, setRunCycleActive] = useState(false);
  const { entityStatus, handleSocketEvent } =
  (useSocket() as SocketContextProps) || {};

  const isScheduleIdNullOrUndefined =
  gridParams.data.schedule_id === null ||
  gridParams.data.schedule_id === undefined;

  const status = checkStatus(gridParams?.data, entityStatus);
  let actions;
  if (status === null) {
    actions = actionsData.map((item: any) => ({
      ...item,
      disabled:
        isScheduleIdNullOrUndefined ||
        (item.disabled !== undefined && item.disabled),
    }));
  }
  if (status === "In Progress") {
    actions = actionsInprogress.map((item: any) => ({
      ...item,
      disabled:
        isScheduleIdNullOrUndefined ||
        (item.disabled !== undefined && item.disabled),
    }));
  }

  const isDisabled = actions.some(
    (action:any) => action?.disabled !== undefined && action?.disabled
  );

  const handleMouseLeave = () => {
    setRunCycleActive(false);
  };

  const handleCloseTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.close();
    }
  };

  const handleActionClick = (action: any) => {
    if (!action.disabled) {
      action.onClick(gridParams.data);
      if (action.label === "Run Cycle") {
        setRunCycleActive(!runCycleActive);
      }
      // Close the popup after the action is performed
      handleCloseTooltip();
    }
  };

  return (
    <div className="flex items-center">
      <Popup
        closeOnEscape
        className="actionPopup "
        ref={tooltipRef}
        trigger={
          <button
            className="text-xl"
            onMouseLeave={() => setRunCycleActive(false)}
          >
            <HiDotsVertical
              className={runCycleActive ? "text-[#09090a]" : "text-[#919EAB]"}
              onClick={() => setRunCycleActive(!runCycleActive)}
            />
          </button>
        }
        position="left top"
        arrow={false}
        closeOnDocumentClick
      >
        <div className="grid gap-[4px] ">
          {actions
            ?.filter((a:any) => !a.hide)
            ?.map((action: any, index: any) => {
              const isActionDisabled =
                isDisabled ||
                (action?.disabled !== undefined && action?.disabled);

              return (
                <li
                  className={classNames(
                    "flex items-center cursor-pointer gap-1.5 text-[#181B20] h-[36px] hover:text-[#004CFF] pt-[6px] pb-[4px] hover:rounded-md hover:bg-[#EDF0F580] hover:scale-25 text-sm",
                    isActionDisabled ? "!text-gray-400 !cursor-not-allowed" : ""
                  )}
                  key={index}
                  onClick={() => {
                    if (!isActionDisabled) {
                      action.onClick(gridParams.data);
                    }
                    handleCloseTooltip();
                  }}
                >
                  {action.icon}
                  { action.label}
                </li>
              );
            })}
        </div>
      </Popup>
    </div>
  );
};

export default TestCycleActionPopup;
