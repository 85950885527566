import React, { useMemo } from "react";
import classNames from "classnames";

interface BadgeProps {
  status: Type;
}

export enum Type {
  FAIL = "fail",
  CANCELLED = "cancelled",
  IN_QUE = "in que",
  PASS = "pass",
  IN_PROGRESS = "in progress",
  NOT_TESTED = "not tested",
  NOT_STARTED = "not started",
}

const Badge = ({ status }: BadgeProps) => {
  const statusClass = useMemo(() => {

    switch (status.toLowerCase()) {
      case Type.PASS:
        return `bg-pass text-textPass w-[60px] h-[24px]`;
      case Type.FAIL:
        return `!bg-fail text-textFail w-[60px] h-[24px]`;
      case Type.CANCELLED:
        return `bg-cancelled text-textCancelled w-[77px] h-[24px]`;
      case Type.IN_QUE:
        return `bg-inQue text-textInQue w-[60px]  h-[24px]`;
      case Type.IN_PROGRESS:
      case Type.NOT_STARTED:
        return `bg-progressStarted text-textProgressStarted w-[81px] h-[24px]`;
      case Type.NOT_TESTED:
        return `bg-textInQue text-textProgressStarted w-[81px] h-[24px]`;
      default:
        return "-";
    }
  }, [status]);

  return (
    <>
      <p
        className={classNames(
          "text-[#26D237] normal-case font-semibold rounded-[6px] flex items-center justify-center pt-[2px] pr-[8px] pb-[2px] pl-[8px]",
          statusClass
        )}
      >
        &bull;
        <span className="mx-1 capitalize text-[12px] leading-[20px] text-center ">
          {status?.toLowerCase?.()}
        </span>
      </p>
    </>
  );
};

export default Badge;
