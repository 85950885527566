import { HiDotsVertical } from "react-icons/hi";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import React, { useRef, useState } from "react";
import classNames from "classnames";

const ActionMenu = ({ actions, btnLabel, position }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef: any = useRef(null);

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleCloseTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.close();
      setIsButtonClicked(false);
    }
  };
  const handleButtonClick = () => {
    if (isOpen === false) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
    setIsOpen(!isOpen);
  };
  return (
      <Popup
        className="testingqueuePopup"
        open={isOpen}
        ref={tooltipRef}
        trigger={
          btnLabel ? (
            <div
              style={{
                border: "1px solid #004CFF",
                width: "95px",
                height: "32px",
                borderRadius: "4px",
                padding: "6px 16px 6px 8px",
                backgroundColor: isButtonClicked ? "#CCDBFF" : "white",
              }}
              className={classNames(
                "border rounded absolute !border-[#004CFF] text-[#004CFF]",
                isOpen ? "" : "first-letter:"
              )}
            >
              <button onClick={handleButtonClick}>
                <div className="flex gap-[6px]">
                  <HiDotsVertical
                    style={{ fontWeight: "600", fontSize: "15px" }}
                  />
                  <span className="w-[45px] h-[20px] text-[14px] leading-[19.6px] font-semibold">
                    {btnLabel}
                  </span>
                </div>
              </button>
            </div>
          ) : (
            <button>
              <HiDotsVertical />
            </button>
          )
        }
        position={position || "left top"}
        arrow={false}
        closeOnDocumentClick
      >
        <div className="grid gap-[6px] ">
          {actions?.map((action: any, index: any) => (
            <div
              className="element popup_Options flex items-center cursor-pointer  hover:text-[#004CFF] p-2"
              key={index}
              onClick={() => {
                action.callback();
                handleCloseTooltip();
              }}
            >
              <div className="flex gap-2 hover:text-[#004CFF]">
                <span>{action.icon}</span>
                <label className="leading-[17.6px] text-[14px] cursor-pointer ">
                  {action.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </Popup>
  );
};

export default ActionMenu;
