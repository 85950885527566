// src/index.js
import React, { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import Auth0ProviderWithHistory from "./Auth0Provider";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </StrictMode>
);
