import { useState, useRef } from "react";
import { PiFilePngDuotone, PiFileXlsDuotone } from "react-icons/pi";
import { AiOutlineDownload } from "react-icons/ai";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import TooltipPopover from "./Tooltip";
import { BiSolidDownArrow } from "react-icons/bi";
import Loader from "./Loader";
import axios from "axios";
import { useMyContext } from "../myContext";
import * as htmlToImage from "html-to-image";
import {
  createPromiseCallback,
  simulateDownload,
} from "../helper/utility-functions";

interface DefectTableProps {
  epicDefectData: any;
  loading: any;
}
interface EpicDefectData {
  id: string;
  epic_name: string;
  pass_count: number;
  fail_count: number;
  not_tested_yet_count: number;
  total_count: number;
  // Add other properties as needed
}

const DefectTable = ({ epicDefectData, loading }: DefectTableProps) => {
  const { token, selectedOrg, selectedEnv, selectedProject }: any =
    useMyContext();
  const tooltipRef: any = useRef(null);
  const containerRef: any = useRef(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const base_url = process.env.REACT_APP_API_URL;

  const [isOpen, setIsOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const handleCloseTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.close();
      setIsButtonClicked(false);
    }
  };
  const handleButtonClick = () => {
    if (isOpen === false) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
    setIsOpen(!isOpen);
  };
  const dummy = [
    {
      id: 1,
      name: "Epic Name 01",
      defects: 3,
      pending: 10,
      pass: 8,
      ticket: "12323232",
      story: "User Story Name",
      total: 19,
    },
    {
      id: 2,
      name: "Epic Name 01",
      defects: 20,
      pending: 40,
      pass: 40,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 3,
      name: "Epic Name 01",
      defects: 5,
      pending: 25,
      pass: 70,
      ticket: "12323232",
      story: "User Story",
      total: 100,
    },
    {
      id: 4,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 5,
      name: "Epic Name 01",
      defects: 5,
      pending: 25,
      pass: 70,
      ticket: "12323232",
      story: "User Story",
      total: 100,
    },
    {
      id: 6,
      name: "Epic Name 01",
      defects: 3,
      pending: 10,
      pass: 8,
      ticket: "12323232",
      story: "User Story User Story",
      total: 19,
    },
    {
      id: 7,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 8,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 9,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 10,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
    {
      id: 11,
      name: "Epic Name 01",
      defects: 20,
      pending: 60,
      pass: 20,
      ticket: "12323232",
      story: "User Story",
      total: 19,
    },
  ];
  const [tooltipVisible, setTooltipVisible] = useState(
    Array(dummy.length).fill(false)
  );

  const actions = [
    {
      label: "as.PNG",
      title: "png",
      callback: () => handleExportAsPNG(),
      icon: <PiFilePngDuotone className="text-xl" />,
    },
    {
      label: "as.XLS",
      title: "xls",
      callback: () => handleExportXLS("xls"),
      icon: <PiFileXlsDuotone className="text-xl" />,
    },
  ];
  const handleMouseEnter = (index: any) => {
    const newTooltipVisible = [...tooltipVisible];
    newTooltipVisible[index] = true;
    setTooltipVisible(newTooltipVisible);
  };

  const handleMouseLeave = (index: any) => {
    const newTooltipVisible = [...tooltipVisible];
    newTooltipVisible[index] = false;
    setTooltipVisible(newTooltipVisible);
  };
  const handleExportXLS = (type: string) => {
    if (type === "png") return alert("This feature is coming soon");
    const tokenString: string | null = localStorage.getItem("token");
    setExportLoading(true);

    const todayDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");

    let url = `${base_url}/api/v1/testcase/defects_epics_overview/export?organization_id=${selectedOrg?.value}&project_id=${selectedProject?.value}&format_file=${type}`;

    if (selectedEnv?.value) {
      url += `&environment_id=${selectedEnv.value}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${tokenString}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });

          const link = document.createElement("a");

          link.download = `${todayDate}TestExecution.xls`;

          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setExportLoading(false));
  };

  const handleExportAsPNG = async () => {
    try {
      const todayDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
      const dynamicDiv = dynamicDivElements();
      let x = window.open(void 0, "_popup", "width=800,height=600");
      // Calculate the center of the screen
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = 800;
    const popupHeight = 600;
    const centerX = (screenWidth - popupWidth) / 2;
    const centerY = (screenHeight - popupHeight) / 2;

    // Move the popup window to the center
    x?.moveTo(centerX, centerY);

      x?.document.body.appendChild(dynamicDiv);
      const allStyles = document.querySelectorAll(
        'style, link[rel="stylesheet"]'
      );
      allStyles.forEach((styleNode) => {
        const copy = styleNode.cloneNode(true);
        x?.document.body.appendChild(copy);
      });
      const cb = createPromiseCallback();
      setTimeout(() => cb(), 500);
      cb.promise.then(() => {
        htmlToImage
          .toPng(x?.document.body as HTMLElement, { backgroundColor: "white" })
          .then((dataUrl) => {
            simulateDownload(dataUrl, `${todayDate}image.png`).then(() => {
              x?.close();
            });
           
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      });
     
    } catch (error) {
      console.error("Error exporting as PNG:", error);
      alert("Error exporting as PNG:");
    }
  };

  const dynamicDivElements = () => {
    const dynamicDiv = document.createElement("div");

    dynamicDiv.innerHTML = `
      <div class="flex flex-col gap-[16px] pl-[28px] pr-[24px] pt-[24px] pb-[20px] ">
        <div class="flex justify-between  !h-[36px] font-montserrat">
          <div class="top-div font-bold font-montserrat leading-[24.3px] text-[18px] w-[361px] h-[24px]">
            Latest Defects Test execution per Epic
          </div>
        </div>
        <div class="flex flex-col gap-3  font-sans-pro  ">
          ${
            epicDefectData.length === 0
              ? '<div class="w-full h-full flex items-center justify-center">No data found.</div>'
              : epicDefectData
                  .map((item: EpicDefectData, index: number) => {
                    const pass = +(
                      (item.pass_count / item.total_count) *
                      100
                    ).toFixed(2);
                    const defect = +(
                      (item.fail_count / item.total_count) *
                      100
                    ).toFixed(2);
                    const pending = +(
                      (item.not_tested_yet_count / item.total_count) *
                      100
                    ).toFixed(2);
                    return `
                  <div class="flex justify-between gap-1" key=${index}>
                    <div class="flex gap-1 items-center  h-[20px]">
                      <div class="text-[16px] leading-normal text-[#181B20] not-italic capitalize">
                        ${item.epic_name}
                        <span class="!text-[#181B20]">|</span>
                      </div>
                      <div className=" cursor-pointer text-[#181B20] text-[16px] leading-normal capitalize font-normal">
                        ${item.fail_count}
                      </div>
                    </div>
                    <div class="flex !w-[300px] items-center  bg-liteGray rounded">
                      <div class="w-[300px] flex gap-[2px] bg-liteGray rounded">
                        ${
                          item.pass_count !== 0
                            ? `<div
                          style="width: ${pass}%"
                          class="bg-[#7DE487] rounded text-[14px] text-center text-white leading-normal font-normal"
                        >
                          ${item.pass_count}
                        </div>`
                            : ` `
                        }
                        ${
                          item.fail_count !== 0
                            ? `<div
                          style="width: ${defect}%"
                          class="bg-litePink rounded text-[14px] text-center text-white"
                        >
                          ${item.fail_count}
                        </div>`
                            : ` `
                        }
                        ${
                          item.not_tested_yet_count !== 0
                            ? `<div
                          style="width: ${pending}%"
                          class="bg-liteGray text-[14px] text-center rounded"
                        >
                          ${item.not_tested_yet_count}
                        </div>`
                            : ` `
                        }
                      </div>
                    </div>
                  </div>
                `;
                  })
                  .join("")
          }
        </div>
      </div>
    `;

    return dynamicDiv;
  };

  return (
    <>
      <div
        ref={containerRef}
        className="flex flex-col gap-[16px] border rounded-[12px] pl-[28px] pr-[24px] pt-[24px] pb-[20px] w-[800px] h-[344px]"
      >
        <div className="flex justify-between !w-[748px] !h-[36px] font-montserrat">
          <div className=" top-div font-bold font-montserrat leading-[24.3px] text-[18px] w-[361px] h-[24px]">
            Latest Defects Test execution per Epic
          </div>
          <div className="relative exportPopupButton">
            <Popup
              className="exportPopup"
              ref={tooltipRef}
              trigger={
                <div
                  className={`border rounded-lg w-[102px] h-[36px] p-[8px] !border-[#004CFF] ${
                    isButtonClicked ? "bg-[#CCDBFF]" : "bg-white"
                  }`}
                >
                  <button
                    className="cursor-pointer gap-2  rounded items-center flex justify-center"
                    onClick={handleButtonClick}
                  >
                    {exportLoading ? (
                      <div className="flex justify-center items-center w-[85px] h-[16px]">
                        <Loader
                          size={30}
                          color={"#224BF8"}
                          loading={exportLoading}
                        />
                      </div>
                    ) : (
                      <>
                        <AiOutlineDownload className="text-[#004CFF] w-[20px] h-[20px]" />

                        <span className="text-[14px] leading-normal font-semibold not-italic text-[#004CFF] ">
                          Export...
                        </span>
                      </>
                    )}
                  </button>
                </div>
              }
              position="bottom center"
              arrow={false}
              closeOnDocumentClick
            >
              {actions?.map((action: any, index: any) => (
                <li
                  className="exportPopupBG popup_Options flex items-center  cursor-pointer gap-2 hover:text-[#004CFF]"
                  key={index}
                  onClick={() => {
                    action.callback();
                    handleCloseTooltip();
                  }}
                >
                  {action.icon} {action.label}
                </li>
              ))}
            </Popup>
          </div>
        </div>
        <div className="flex flex-col gap-[4px] h-[260px] font-sans-pro overflow-y-auto !w-[748px]">
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <Loader size={60} color={"#224BF8"} loading={loading} />
            </div>
          ) : epicDefectData.length === 0 ? (
            <div className=" w-full h-full flex items-center justify-center">
              No data found.
            </div>
          ) : (
            epicDefectData.map((item: any, index: any) => {
              const pass = +(
                (item.pass_count / item.total_count) *
                100
              ).toFixed(2);
              const defect = +(
                (item.fail_count / item.total_count) *
                100
              ).toFixed(2);
              const pending = +(
                (item.not_tested_yet_count / item.total_count) *
                100
              ).toFixed(2);
              return (
                <div
                  className=" hover:bg-[#F2F6FF] px-[4px] py-[6px] flex justify-between gap-1"
                  key={index}
                >
                  <div className="flex gap-1 items-center w-[436px] h-[20px]">
                    <div className="text-[16px] leading-normal text-[#181B20] not-italic capitalize">
                      {item.epic_name}
                      <span className="text-[#181B20]">|</span>
                    </div>
                    <div
                      className={`${
                        index == 0 || index == 1 ? null : "relative"
                      }`}
                      key={item.id}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <div className=" cursor-pointer text-[#181B20] text-[16px] leading-normal capitalize font-normal">
                        {item.fail_count}
                      </div>
                      {tooltipVisible[index] && (
                        <TooltipPopover
                          className=""
                          content={
                            <div className=" ">
                              <div className=" bg-white top-[-86px] bottom-[8px] left-[-62px] absolute z-10 grid toolTipDefectTest w-[129px] h-[50px] rounded-[8px] p-[8px]">
                                <div className="font-semibold text-[#181B20] leading-[18px] text-[14px]">
                                  {item.fail_count}
                                </div>
                                <div className="text-[#919EAB] text-[14px] not-italic font-normal leading-[16px]">
                                  Total No. of Defects
                                </div>
                                <div>
                                  <div className="flex justify-center items-center">
                                    <BiSolidDownArrow
                                      width={12}
                                      height={6}
                                      fill="white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex w-[308px] items-center">
                    <div className="w-[300px] h-[21px] flex gap-[2px] bg-[#E5E8EB]/60 rounded">
                      {item.pass_count !== 0 && (
                        <div
                          style={{ width: `${pass}%` }}
                          className="bg-[#7DE487] rounded text-[14px] text-center text-white leading-normal font-normal"
                        >
                          {item.pass_count}
                        </div>
                      )}
                      {item.fail_count !== 0 && (
                        <div
                          style={{ width: `${defect}%` }}
                          className="bg-[#F3749D] rounded text-[14px] text-center text-white"
                        >
                          {item.fail_count}
                        </div>
                      )}
                      {item.not_tested_yet_count !== 0 && (
                        <div
                          style={{ width: `${pending}%` }}
                          className="bg-[#E5E8EB] text-[14px] text-center rounded"
                        >
                          {item.not_tested_yet_count}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default DefectTable;
