// import { TabNavigator } from "@/components/TabNavigator";
import TabNavigator, { TabNavigatorTabType } from "../components/TabNavigator";
// import Testing from "@/components/testing";
import { useMemo } from "react";
const TestingQueue = () => {
  const tabs: TabNavigatorTabType[] = useMemo(
    () => [
      {
        id: "",
        label: "Test Cycles",
        link: "/",
      },
      {
        id: "",
        label: "Defect Management",
        link: "/defect-management",
      },
      {
        id: "",
        label: "Testing Que",
        link: "/testing-queue",
      },
      {
        id: "",
        label: "Testing Server",
        link: "/testing-server",
        active: true,
        hideCredits: true,
      },
    ],
    []
  );
  return (
    <>
      <div className="flex flex-col !w-[1376px]">
        <div className="flex justify-between gap-2 mb-6 items-center w-full">
          <div className="w-full max-w-2xl">
            <TabNavigator tabs={tabs} flex />
          </div>
          {/* <div className="flex w-[120px] h-[44px] gap-2 p-[12px] border rounded  !border-[#004CFF] text-[#004CFF]">
            <img src={addIcon} alt="frame" className="!w-[20px] !h-[20px]" />
            <button className="text-[14px] font-semibold leading-normal">
              Add Server
            </button>
          </div> */}
        </div>
        {/* <TestingSever /> */}
        <div className="h-full w-[1376px]">
          <div className="text-center flex justify-center items-center h-96">
            Coming soon
          </div>
        </div>
      </div>
    </>
  );
};
export default TestingQueue;
