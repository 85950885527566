export const SelectStyle = {
  container: (provided: any) => ({
    ...provided,
    width: "360px",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #ccc",
    borderRadius: "8px",
    height: "56px",
    padding: "16px ",
  }),
  option: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (provided: any) => ({
    ...provided,
    marginTop: "-5px",
    marginLeft: "-6px",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    marginTop: "-10px", 
    color:"#919EAB !important"
  }),
 
};

export const SelectStyle2 = {
  container: (provided: any) => ({
    ...provided,
    width: "170px",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #ccc",
    borderRadius: "8px",
    height: "56px",
    padding: "16px !important",
  }),
  singleValue: (base: any) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    gap: "4px",
  }),
  option: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({ display: "none" }),
};
