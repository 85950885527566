import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import LoginButton from "./comp";
import Layout from "./components/layout";
import React,{ useEffect,useState } from "react";
import { Route,Router,Routes,Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoutes";
import TestCycles from "./pages/test-cycles";
import DefectPage from "./pages/defectPage";
import TestingQue from "./pages/testingQue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import TestingServer from "./pages/testingServer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutContext } from "./myContext/layoutContext";
import { SocketProvider } from "./components/context/socketcontext";

function App() {
  const [showCreditIndicators,setShowCreditIndicator] = useState(false);
  const [projectId,setProjectId] = useState("");
  const [organizationId,setOrganizationId] = useState("");
  return (
    <>
      {/* <LayoutContext.Provider
        value={{ showCreditIndicators, setShowCreditIndicator }}
      > */}
      {/* <Layout>
          <Routes>
            <Route path="/" element={<TestCycles />}  />
            <Route path="/defect-management" element={<DefectPage />} />
            <Route path="/testing-queue" element={<TestingQue />} />
            <Route path="/testing-server" element={<TestingServer />} />
          </Routes>
          <ToastContainer />
        </Layout>
      </LayoutContext.Provider> */}

      <LayoutContext.Provider
        value={{ showCreditIndicators,setShowCreditIndicator }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                setProjectId={setProjectId}
                setOrganizationId={setOrganizationId}
              >
                <Routes>
                  <Route
                    index
                    element={
                      <SocketProvider>
                        <TestCycles
                          projectId={projectId}
                          organizationId={organizationId}
                        />{" "}
                      </SocketProvider>
                    }
                  />
                </Routes>
              </Layout>
            }
          />
          <Route
            path="/defect-management/*"
            element={
              <Layout
                setProjectId={setProjectId}
                setOrganizationId={setOrganizationId}
              >
                <Routes>
                  <Route index element={<DefectPage />} />
                </Routes>
              </Layout>
            }
          />
          <Route
            path="/testing-queue/*"
            element={
              <Layout
                setProjectId={setProjectId}
                setOrganizationId={setOrganizationId}
              >
                <Routes>
                  <Route index element={<TestingQue />} />
                </Routes>
              </Layout>
            }
          />
          <Route
            path="/testing-server/*"
            element={
              <Layout
                setProjectId={setProjectId}
                setOrganizationId={setOrganizationId}
              >
                <Routes>
                  <Route index element={<TestingServer />} />
                </Routes>
              </Layout>
            }
          />
        </Routes>
        <ToastContainer />
      </LayoutContext.Provider>
    </>
  );
}

export default App;
