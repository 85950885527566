import React from "react";

const EditIcon = () => {
  return (
    <svg
      className="mx-1 !w-[20px]"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="20px / Icons / Edit" opacity="0.8">
        <path
          id="Color"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3488 8.89881L11.103 6.65298L12.7263 5.02881L14.9713 7.27381L13.3488 8.89881ZM7.56635 14.6871L5.08551 14.913L5.30551 12.4496L9.98635 7.76882L12.233 10.0155L7.56635 14.6871ZM16.1697 6.11465L16.1688 6.11381L13.8872 3.83215C13.2697 3.21631 12.2088 3.18715 11.6238 3.77465L4.12718 11.2713C3.85551 11.5421 3.68718 11.9021 3.65218 12.283L3.33635 15.758C3.31468 16.0038 3.40218 16.2471 3.57718 16.4221C3.73468 16.5796 3.94718 16.6663 4.16635 16.6663C4.19218 16.6663 4.21718 16.6655 4.24218 16.663L7.71718 16.3471C8.09885 16.3121 8.45801 16.1446 8.72885 15.8738L16.2263 8.37631C16.833 7.76798 16.8072 6.75298 16.1697 6.11465Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default EditIcon;
