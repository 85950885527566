import TabNavigator, { TabNavigatorTabType } from "../components/TabNavigator";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-enterprise";
import plusIcon from "../images/Plus.svg";
import "../style/testCycleStyles.css";
import ActionPopup from "../components/modals/ActionPopup";
import { useEffect, useMemo, useRef, useState } from "react";
import ScheduleTestPopup from "../components/modals/ScheduleTestPopup";
import DeleteSchedule from "../components/modals/DeleteSchedule";
import { useMyContext } from "../myContext";
import { FiPlay } from "react-icons/fi";
import EditIcon from "../components/EditIcon";
import TrashSvg from "../commonComponents/TrashSvg";
import TestCycleAction from "../components/modals/TestCycleAction";
import axios from "axios";
import Loader from "../components/Loader";
import { checkStatus, processTestData } from "../helper/utility-functions";
import { IoLinkOutline, IoPauseCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";
import {
  SocketContextProps,
  useSocket,
} from "../components/context/socketcontext";

const TestCycle = ({ projectId, organizationId }: any) => {
  const {
    EnvLoading,
    projectLoading,
    environmentOption,
    selectedEnv,
    token,
    selectedProject,
  }: any = useMyContext();
  const testCaseLink = process.env.REACT_APP_AUDIENCE;
  const [modalIsOpen, setModalIsOpen] = useState<any>(false);
  const base_url = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalIsOpenRemoveUser, setRemoveUserModalOpen] = useState(false);
  const [testCycleData, setTestCycleData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [scheduleId, setScheduleId] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [name, setName] = useState();
  const [scheduleDataLoaidng, setScheduleDataLoaidng] = useState(false);
  const [isStatus, setStatus] = useState<any>();

  const { entityStatus, handleSocketEvent } =
    (useSocket() as SocketContextProps) || {};

  const socketLink: any = process.env.REACT_APP_API_URL;

  const socketRef = useRef<Socket | null>(null);

  const deleteSchedule = async (id: number) => {
    try {
      setDeleteLoading(true);
      const response = await axios.delete(
        `${base_url}/api/v1/schedule-test/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDeleteLoading(false);
      if (response?.status === 200) {
        toast.success("Deleted successfully");
        getTestCyclesData();
        setRemoveUserModalOpen(false);
      }
      setRemoveUserModalOpen(false);
    } catch (err) {
      setRemoveUserModalOpen(false);
      console.log(err);
      setDeleteLoading(false);
    }
  };

  const runSchedule = async (id: number) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${base_url}/api/v1/schedule-test/${id}/run`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response?.status === 200) {
        toast.success("Run successfully");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const suspendSchedule = async (id: number) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${base_url}/api/v1/schedule-test/${id}/suspend`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response?.status === 200) {
        toast.success("Suspend successfully");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getScheduleDataById = async (id: number) => {
    try {
      setScheduleDataLoaidng(true);
      const response = await axios.get(
        `${base_url}/api/v1/schedule-test/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        setRowData(response?.data);
      }
      setScheduleDataLoaidng(false);
    } catch (err) {
      console.log(err);
      setScheduleDataLoaidng(false);
    }
  };

  const commonActions = [
    {
      label: "Edit Schedule",
      onClick: (element: any) => {
        getScheduleDataById(element?.schedule_id);
        setRowData(element);
        setOpenEditModal(true);
      },
      icon: <EditIcon />,
      disabled: false,
    },
    {
      label: "Delete Schedule",
      onClick: (element: any) => {
        setName(element?.Type?.[element?.Type?.length - 1]);
        handleOpenRemoveUserModal();
        setScheduleId(element?.schedule_id);
      },
      icon: <TrashSvg />,
      disabled: false,
    },
  ];

  const actions = [
    {
      label: isStatus === "InProgress" ? "Suspend" : "Run Cycle",
      onClick: (element: any) => {
        if (isStatus === "InProgress") {
          suspendSchedule(element?.schedule_id);
        } else {
          runSchedule(element?.schedule_id);
        }
      },
      icon:
        isStatus === "InProgress" ? (
          <IoPauseCircleOutline size={20} className="mx-1 !w-[20px]" />
        ) : (
          <FiPlay size={20} className="mx-1 !w-[20px]" />
        ),
      disabled: false,
    },
    ...commonActions,
  ];

  const actionsInprogress = [
    {
      label: "Suspend",
      onClick: (element: any) => {
        suspendSchedule(element?.schedule_id);
      },
      icon: <IoPauseCircleOutline size={20} className="mx-1 !w-[20px]" />,
      disabled: false,
    },
    ...commonActions,
  ];
  const handleCloseRemoveUserModalPopup = () => {
    setRemoveUserModalOpen(false);
  };

  const handleOpenRemoveUserModal = () => {
    setRemoveUserModalOpen(true);
  };
  const tabs: TabNavigatorTabType[] = useMemo(
    () => [
      {
        id: "",
        label: "Test Cycles",
        link: "/test-cycle",
        active: true,
      },
      {
        id: "",
        label: "Defect Management",
        link: "/defect-management",
      },
      {
        id: "",
        label: "Testing Que",
        link: "/testing-queue",
        hideCredits: true,
      },
      {
        id: "",
        label: "Testing Servers",
        link: "/testing-server",
      },
    ],
    []
  );
  const gridRef = useRef<any>();

  const autoGroupColumnDef: AgGridReactProps["autoGroupColumnDef"] =
    useMemo(() => {
      return {
        headerName: "Type",
        rowGroup: true,
        width: 600,
        headerComponent: () => (
          <div className="font-semibold text-[16px] text-[#181B20] leading-[20.11px] ml-[44px]">
            Type
          </div>
        ),
        cellClass: "justify-start flex",

        cellRendererParams: {
          suppressCount: true,
          innerRenderer: (node: any) => {
            const trimUrl = testCaseLink?.replace(/\/$/, "");
            return (
              <>
                {node?.data.Type.length == 5 ? (
                  <div className="truncate flex w-full items-center">
                    <GoDotFill className="text-[#919EAB] h-[6px] w-[6px] mr-[12px]" />
                    <p className="truncate mr-[4.83px]">{node?.value}</p>
                    {node?.data?.url && (
                      <>
                        <IoLinkOutline className="w-[20px] h-[20px] text-[#1B0076]" />
                        <Link
                          to={`${trimUrl}${node?.data?.url}`}
                          className="truncate underline text-[#1B0076] ml-[4px] cursor-pointer"
                        >
                          {node?.value}
                        </Link>
                      </>
                    )}
                  </div>
                ) : (
                  <p>{node?.value}</p>
                )}
              </>
            );
          },
        },
      };
    }, []);

  const gridOptions: AgGridReactProps["gridOptions"] = useMemo(() => {
    return {
      groupSuppressAutoColumn: true,
      suppressMovable: true,
      columnDefs: [
        {
          field: "scheduled",
          headerName: "Scheduled",
          rowGroup: false,
          width: 170,
          cellClass: "!items-center text-[#181B20] !flex !justify-center",
          headerClass: "!items-center !flex !justify-center",
        },
        {
          field: "no_of_test_cases",
          headerName: "No. of Test Cases",
          rowGroup: false,
          width: 172,
          cellClass: "!items-center text-[#181B20] !flex !justify-center",
          headerClass: "!items-center !flex !justify-center",
        },
        {
          field: "last_test_run",
          headerName: "Last Test Run",
          rowGroup: false,
          width: 172,
          cellClass: "!items-center text-[#181B20] !flex !justify-center",
          headerClass: "!items-center !flex !justify-center",
        },
        {
          field: "defect_found",
          headerName: "Defect Found",
          width: 172,
          rowGroup: false,
          headerClass: "!items-center !flex !justify-center",
          cellRenderer: (gridParams: any) => {
            return (
              <>
                {gridParams.data.defect_found !== "-" &&
                parseInt(gridParams.data.defect_found, 10) > 0 ? (
                  <p className="underline w-full !text-[#1B0076] cursor-pointer !items-center !flex !justify-center">
                    {gridParams.data.defect_found}
                  </p>
                ) : (
                  <p className="w-full !text-[#919EAB] cursor-pointer !items-center !flex !justify-center">
                    0
                  </p>
                )}
              </>
            );
          },
        },

        {
          headerClass: "items-center flex justify-center",
          rowGroup: true,
          cellClass: "items-center flex justify-center",
          field: "Actions",
          width: 88,
          cellRenderer: (gridParams: any) => {
            const isScheduleIdNullOrUndefined =
              gridParams.data.schedule_id === null ||
              gridParams.data.schedule_id === undefined;
            const status = checkStatus(gridParams?.data, entityStatus);
            setStatus(status);
            const isDisabled = isScheduleIdNullOrUndefined;
            return (
              <>
                <TestCycleAction
                  actionsData={actions}
                  actionsInprogress={actionsInprogress}
                  isDisabled={isDisabled}
                  gridParams={gridParams}
                />
              </>
            );
          },
        },
      ],
      autoGroupColumnDef: autoGroupColumnDef,
    };
  }, [actions, actionsInprogress, entityStatus]);

  const rowStyle = {
    height: " 44px",
    minHeight: "44px",
    borderRadius: "6px",
    width: " 1372px ",
  };
  const getDataPath = useMemo(() => {
    return (data: any) => {
      return data.Type;
    };
  }, []);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };
  const getTestCyclesData = async () => {
    try {
      const envQuery = selectedEnv
        ? `&environment_id=${selectedEnv?.value}`
        : "";

      setLoading(true);
      const res = await axios.get(
        `${base_url}/api/v1/testcase/test-cycles?organization_id=${organizationId?.value}&project_id=${projectId?.value}${envQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        const correctedData = processTestData(res.data);
        setTestCycleData(correctedData);
        setLoading(false);
        return;
      }
      setTestCycleData([]);
      setLoading(false);
    } catch (err) {
      setTestCycleData([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (organizationId?.value && projectId?.value && selectedEnv?.value)
      getTestCyclesData();
  }, [selectedEnv, organizationId?.value, projectId?.value]);

  useEffect(() => {
    socketRef.current = io(socketLink, {
      transports: ["websocket"],
      auth: {
        token: token,
      },
    });

    const socket = socketRef.current;
    socket.on("connect", () => {
      console.log("===> Socket connected:", socket.connected);
    });

    socket.on("connect_error", (error: any) => {
      console.error("===> Socket connection error:", error);
    });

    socket.on("test_case:update", (data: any) => {
      const parsedData = JSON.parse(data);
      const newData: any = {
        id: parsedData?.testcase_id,
        status: parsedData?.status,
      };
      handleSocketEvent?.("test_case", newData);
    });

    socket.on("story:update", (data: any) => {
      const parsedData = JSON.parse(data);
      const newData: any = {
        id: parsedData?.id,
        status: parsedData?.status,
      };
      handleSocketEvent?.("story", newData);
    });
    socket.on("epic:update", (data: any) => {
      const parsedData = JSON.parse(data);
      const newData: any = {
        id: parsedData?.id,
        status: parsedData?.status,
      };
      handleSocketEvent?.("epic", newData);
    });
    socket.on("project:update", (data: any) => {
      const parsedData = JSON.parse(data);
      const newData: any = {
        id: parsedData?.id,
        status: parsedData?.status,
      };
      handleSocketEvent?.("project", newData);
    });
    socket.on("test_step:update", (data: any) => {
      const parsedData = JSON.parse(data);
      const newData: any = {
        id: parsedData?.id,
        status: parsedData?.status,
      };
      handleSocketEvent?.("test_step", newData);
    });
    socket.on("disconnect", (reason: any) => {
      console.log("===> Socket disconnected. Reason:", reason);
    });
    // @ts-ignore
    window.socket = socket;
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("===> Socket disconnected in cleanup");
      }
    };
  }, [token, socketLink, handleSocketEvent]);

  return (
    <>
      {modalIsOpen ? (
        <ScheduleTestPopup
          handleCloseModal={handleCloseModal}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          projectId={projectId}
          organizationId={organizationId}
          getTestCyclesData={getTestCyclesData}
          modalType={"ADD"}
        />
      ) : null}
      {openEditModal ? (
        <ScheduleTestPopup
          handleCloseModal={handleEditModalClose}
          modalIsOpen={openEditModal}
          setModalIsOpen={setOpenEditModal}
          projectId={projectId}
          organizationId={organizationId}
          getTestCyclesData={getTestCyclesData}
          initialData={rowData}
          modalType={"EDIT"}
          scheduleDataLoaidng={scheduleDataLoaidng}
        />
      ) : null}

      {modalIsOpenRemoveUser && (
        <DeleteSchedule
          name={name}
          handleCloseRemoveUserModalPopup={handleCloseRemoveUserModalPopup}
          deleteSchedule={deleteSchedule}
          scheduleId={scheduleId}
          loading={deleteLoading}
          modalIsOpenRemoveUser={modalIsOpenRemoveUser}
        />
      )}
      <div className="flex justify-between gap-2 mb-6 items-center w-full">
        <div className="w-full max-w-2xl">
          <TabNavigator tabs={tabs} flex />
        </div>
        <div
          onClick={() => handleOpenModal()}
          style={{
            cursor:
              environmentOption.length === 0 ? "cursor-not-allowed" : "pointer",
            pointerEvents: environmentOption.length === 0 ? "none" : "auto",
          }}
          className={`flex justify-center items-center rounded-[8px] w-[44px] h-[44px] bg-[#004CFF] ${
            environmentOption.length === 0
              ? "opacity-50 cursor-not-allowed"
              : " cursor-pointer"
          }`}
        >
          <img src={plusIcon} alt="plusIcon" />
        </div>
      </div>
      {loading || projectLoading || EnvLoading ? (
        <div className="flex h-[740px] items-center justify-center">
          <Loader size={60} color={"#224BF8"} loading={true} />
        </div>
      ) : (
        <div
          className="mt-4 ag-theme-alpine w-full test"
          style={{ height: 740, width: "1376px" }}
        >
          <AgGridReact
            ref={gridRef}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
            rowStyle={rowStyle}
            rowData={testCycleData}
            treeData={true}
            animateRows={true}
            gridOptions={gridOptions}
            groupDefaultExpanded={-1}
            getDataPath={getDataPath}
          />
        </div>
      )}
    </>
  );
};
export default TestCycle;
