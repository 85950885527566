import moment from "moment";
import { BsDot } from "react-icons/bs";
import { DateTimeFormat } from "./constants";
import { IoLinkOutline } from "react-icons/io5";

export const classNames = (...className: any) =>
  className.filter(Boolean).join(" ");

export const isValidDate = (date: Date | string | number): boolean => {
  if (["string", "number"].includes(typeof date)) {
    date = new Date(date);
  } // Check if the date is a valid Date object and it's not "Invalid Date"

  return date instanceof Date && !isNaN(date as any);
};

export const toDateOrNull = (value: any, defaultValue: any = null) => {
  if (isValidDate(value)) return new Date(value);
  return defaultValue;
};

/**
 * Converts a value to a number or returns null (or a provided default value) if the value cannot be converted to a number.
 *
 * @function toNumberOrNull
 * @param {any} value - The value to convert to a number.
 * @param {number|null} [defaultValue=null] - The default value to return if the conversion fails. Defaults to null.
 * @returns {number|null} - The numeric representation of the value, or null (or the provided default) if the conversion fails.
 *
 * @example
 * const inputValue = "42";
 * const result = toNumberOrNull(inputValue, null); // Returns the number 42
 *
 * const invalidValue = "not a number";
 * const defaultResult = toNumberOrNull(invalidValue, 0); // Returns the default value 0
 *
 * const noDefault = "not a number";
 * const noDefaultResult = toNumberOrNull(noDefault); // Returns null (default not provided)
 */
export const toNumberOrNull = (value: any, defaultValue: any = null) => {
  const numericValue = parseFloat(value);
  if (!isNaN(numericValue) && !isNaN(+value) && numericValue === +value)
    return numericValue;
  return defaultValue;
};

export const getStatus = (val: any) => {
  switch (val) {
    case "PASS":
      return (
        <>
          <div className="text-[#26D237] bg-[#EAFBEB] normal-case font-semibold rounded h-[60%] my-2 flex items-center justify-center p-1 ">
            <span>Pass</span>
          </div>
        </>
      );
    case "FAIL":
      return (
        <>
          <div
            style={{
              background: "#FDE8EF",
              color: "#CD0043",
              fontWeight: "700",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="!bg-[#FDE8EF] text-[#CD0043] normal-case font-semibold rounded h-[60%] my-2 flex items-center justify-center p-1 "
          >
            <span>
              {" "}
              <BsDot
                style={{
                  color: "#CD0043",
                  fontSize: "14px",
                }}
              />{" "}
              Fail
            </span>
          </div>
        </>
      );
    case "Cancelled":
      return (
        <>
          <div className="!bg-[#919EAB] text-[#FFFFFF] normal-case font-semibold rounded h-[60%] my-2 flex items-center justify-center p-1 ">
            <span>Cancelled</span>
          </div>
        </>
      );
    default:
      break;
  }
};

export const formatSecondsToMinutesAndSeconds = (seconds: any) => {
  const duration = moment.duration(seconds, "seconds");
  const minutes = Math.floor(duration.asMinutes());
  const remainingSeconds = Math.floor(duration.seconds());

  if (minutes > 0 && remainingSeconds > 0) {
    return `${minutes} min ${remainingSeconds} sec`;
  } else if (minutes > 0) {
    return `${minutes} min`;
  } else {
    return `${remainingSeconds} sec`;
  }
};

/**
 * Simulates the download of a file from a given URL.
 *
 * @param {string} url - The URL of the file to be downloaded.
 * @param {string} fileName - The desired name for the downloaded file.
 * @returns {Promise<void>} A promise that resolves when the download simulation is complete.
 * @throws {Error} If the file download fails.
 *
 * @example
 * // Simulate downloading a PDF file from a URL
 * const fileUrl = 'https://example.com/sample.pdf';
 * const desiredFileName = 'downloaded_file.pdf';
 *
 * simulateDownload(fileUrl, desiredFileName)
 *   .then(() => {
 *     console.log('Download simulation successful.');
 *   })
 *   .catch((error) => {
 *     console.error('Download simulation failed:', error.message);
 *   });
 */
export const simulateDownload = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch the file from ${url}`);
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error: any) {
    throw new Error(`Error during download simulation: ${error.message}`);
  }
};

/**
 * Creates a promise-based callback pattern.
 * @returns {{
 *   (err?: any, data?: any): void,
 *   promise: Promise<any>
 * }}
 * @example
 * const callback = createPromiseCallback();
 * callback.promise
 *   .then((data) => {
 *     // Handle success
 *   })
 *   .catch((error) => {
 *     // Handle error
 *   });
 *
 * // To invoke the callback
 * callback(null, result); // Success
 * callback(error); // Error
 */
export const createPromiseCallback: <T = any>() => {
  (err?: any, data?: T): void;
  promise: Promise<T>;
} = () => {
  let cb: any;
  const promise = new Promise((res, rej) => {
    cb = (error: any, data: any) => {
      if (error) rej(error);
      res(data);
    };
  });
  cb.promise = promise;
  return cb;
};
const invalidDate = "Invalid Date";
export const toDefaultDateFormat = (
  _date: number | string | Date,
  format: string = DateTimeFormat.DATE,
  fallback: string = invalidDate
) => {
  if (isValidDate(_date)) return moment(_date).format(format);
  return fallback;
};

export const isFutureTime = (timeString: any) => {
  const selectedTimeString = new Date(timeString).toLocaleTimeString("en-US", {
    hour12: false,
  });

  const currentDateTime = new Date();
  const selectedDateTime = new Date(`1970-01-01T${selectedTimeString}`);

  const currentHours = currentDateTime.getHours();
  const currentMinutes = currentDateTime.getMinutes();

  const selectedHours = selectedDateTime.getHours();
  const selectedMinutes = selectedDateTime.getMinutes();

  const isFuture =
    selectedHours > currentHours ||
    (selectedHours === currentHours && selectedMinutes > currentMinutes);

  return isFuture;
};

export const processTestData = (data: any) => {
  try {
    const epicsObj: any = [];
    data.forEach((item: any) => {
      const filterKey: any = "project";
      const filteredPairs: any = {};

      for (const key in item) {
        if (key.includes(filterKey)) {
          filteredPairs[key] = item[key];
        }
      }

      if (Object.keys(filteredPairs).length) {
        const { project_name, ...restProjectData } = filteredPairs;

        restProjectData["Type"] = [project_name];
        const projectData = {
          Type: [project_name],
          defect_found:
            restProjectData.project_defect_found?.toString?.() || "-",
          project_id: restProjectData.project_id || "-",
          last_test_run:
            toDefaultDateFormat(
              restProjectData.project_last_test_run,
              DateTimeFormat.DATE_TIME,
              "-"
            ) || "-",
          schedule_id: item.schedule_id,
          no_of_test_cases:
            restProjectData.project_no_of_test_cases?.toString?.() || "-",
          scheduled:
            toDefaultDateFormat(
              restProjectData.project_scheduled,
              DateTimeFormat.DATE_TIME,
              "-"
            ) || "-",
          typeName: "project",
        };
        epicsObj.push(projectData);
      }

      if (Array.isArray(item?.environments)) {
        item?.environments.forEach((environment: any) => {
          const { environment_name, epics, ...environmentData } = environment;
          const envData = {
            Type: [item?.project_name, environment_name],
            defect_found: environmentData.environment_defect_found || "-",
            schedule_id: environmentData?.schedule_id,
            environment_id: environmentData.environment_id || "-",
            last_test_run:
              toDefaultDateFormat(
                environmentData.environment_last_test_run,
                DateTimeFormat.DATE_TIME,
                "-"
              ) || "-",
            no_of_test_cases:
              environmentData?.environment_no_of_test_cases || "-",
            scheduled:
              toDefaultDateFormat(
                environmentData.environment_scheduled,
                DateTimeFormat.DATE_TIME,
                "-"
              ) || "-",
            typeName: "environment-data",
          };
          epicsObj.push(envData);

          if (
            Array.isArray(environment?.epics) &&
            environment.epics.length > 0
          ) {
            environment.epics.forEach((epic: any) => {
              const { stories, epic_name, ...restData } = epic;

              const epicData = {
                Type: [
                  item?.project_name,
                  environment?.environment_name,
                  epic_name,
                ],
                schedule_id: restData.schedule_id,
                defect_found: restData.epic_defect_found || "-",
                epic_id: restData.epic_id || "-",
                last_test_run:
                  toDefaultDateFormat(
                    restData.epic_last_test_run,
                    DateTimeFormat.DATE_TIME,
                    "-"
                  ) || "-",
                no_of_test_cases: restData.epic_no_of_test_cases || "-",
                scheduled:
                  toDefaultDateFormat(
                    restData.epic_scheduled,
                    DateTimeFormat.DATE_TIME,
                    "-"
                  ) || "-",
                typeName: "epic",
              };
              epicsObj.push(epicData);

              if (Array.isArray(stories) && stories.length > 0) {
                stories.forEach((story) => {
                  const { test_cases, story_name, ...restStoryData } = story;

                  const storyData = {
                    Type: [
                      item?.project_name,
                      environment?.environment_name,
                      epic_name,
                      story_name,
                    ],
                    schedule_id: restStoryData.schedule_id,
                    defect_found: restStoryData.story_defect_found || "-",
                    story_id: restStoryData.story_id || "-",
                    last_test_run:
                      toDefaultDateFormat(
                        restStoryData.story_last_test_run,
                        DateTimeFormat.DATE_TIME,
                        "-"
                      ) || "-",
                    no_of_test_cases:
                      restStoryData.story_no_of_test_cases || "-",
                    scheduled:
                      toDefaultDateFormat(
                        restStoryData.story_scheduled,
                        DateTimeFormat.DATE_TIME,
                        "-"
                      ) || "-",
                    typeName: "story",
                  };

                  epicsObj.push(storyData);

                  if (Array.isArray(test_cases) && test_cases.length > 0) {
                    test_cases.forEach((testCase) => {
                      const { test_case_name, ...restTestCaseData } = testCase;
                      const testCaseData = {
                        Type: [
                          item?.project_name,
                          environment?.environment_name,
                          epic_name,
                          story_name,
                          test_case_name,
                        ],
                        defect_found:
                          restTestCaseData.test_case_defect_found || "-",
                        schedule_id: restTestCaseData.schedule_id,
                        test_case_id: restTestCaseData.test_case_id || "-",
                        last_test_run:
                          toDefaultDateFormat(
                            restTestCaseData.test_case_last_test_run,
                            DateTimeFormat.DATE_TIME,
                            "-"
                          ) || "-",
                        no_of_test_cases:
                          restTestCaseData.test_case_no_of_test_cases || "-",
                        scheduled:
                          toDefaultDateFormat(
                            restTestCaseData.test_case_scheduled,
                            DateTimeFormat.DATE_TIME,
                            "-"
                          ) || "-",
                        url: restTestCaseData.url || "",
                        typeName: "test_case",
                      };

                      epicsObj.push(testCaseData);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return epicsObj;
  } catch (error) {
    console.log("error", error);
  }
};
export const formatScheduleTime = (date: any, time: any): moment.Moment => {
  const formattedDate = moment(date);
  const formattedTime = moment(time);

  return formattedDate.set({
    hour: formattedTime.get("hour"),
    minute: formattedTime.get("minute"),
    second: formattedTime.get("second"),
    millisecond: formattedTime.get("millisecond"),
  });
};
export const separateDateAndTime = (dateTimeString: string) => {
  const dateTime = moment(dateTimeString);

  const date = dateTime.format("DD/MM/YYYY");
  const time = dateTime.format("hh:mma");

  return {
    date,
    time,
  };
};

interface EntityStatusData {
  epic: Record<string, "In Progress" | null>;
  story: Record<string, "In Progress" | null>;
  test_step: Record<string, "In Progress" | null>;
  project: Record<string, "In Progress" | null>;
  test_case: Record<string, "In Progress" | null>;
}

export const checkStatus = (data: any, entityStatus: EntityStatusData) => {
  const type_name = data.typeName as keyof EntityStatusData;

  if (entityStatus.hasOwnProperty(type_name)) {
    const entity_id = data[`${type_name}_id`] as string;
    const entity_status = entityStatus[type_name][entity_id];

    return entity_status || null;
  }
  return null;
};
