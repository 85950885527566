import React from "react";

interface TooltipPopoverProps {
  className: string;

  content: React.ReactNode;
}

const TooltipPopover: React.FC<TooltipPopoverProps> = ({
  className,
  content,
}: TooltipPopoverProps) => {
  return <div className={`tooltip ${className}`}>{content}</div>;
};

export default TooltipPopover;
