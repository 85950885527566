import axios from "axios";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FiPlay } from "react-icons/fi";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import GridActions from "../commonComponents/GridAction";
import AgGrid from "../commonComponents/grid";
import {
  toDefaultDateFormat,
  toNumberOrNull,
} from "../helper/utility-functions";
import closeIcon from "../images/CloseIcon.svg";
import downArrow from "../images/Down.svg";
import { useMyContext } from "../myContext";
import Loader from "./Loader";

import { Link } from "react-router-dom";
import CloseIcon from "../commonComponents/CloseIcon";
import PauseIcon from "../commonComponents/PauseIcon";
import TrashIcon from "../commonComponents/TrashIcon";
import { DateTimeFormat } from "../helper/constants";
import Badge from "./Badge";

const Testing = () => {
  const gridRef: any = useRef();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedRow, setSelectedRows] = useState<any>([]);
  const [epicsOptionList, setEpicsOptionList] = useState<any>([]);
  const [selectedEpic, setSelectedEpic] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>();

  const {
    token,
    EnvLoading,
    projectLoading,
    orgLoading,
    selectedProject,
    selectedEnv,
    selectedOrg,
  }: any = useMyContext();
  const base_url = process.env.REACT_APP_API_URL;

  const statusOptions = [
    { value: "PASS", label: "Pass" },
    { value: "FAIL", label: "Fail" },
    // { value: "IN QUE", label: "In Que" },
    // { value: "Cancelled", label: "Cancelled" },
  ];
  const [rowData, setRowData] = useState<any>([]);
  const [testingParamsList, setTestimgParamsList] = useState<any>({
    page: 1,
    limit: 10,
    forcePage: 0,
  });
  const [columnDefs] = useState<any>([
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      maxWidth: 40,
      minWidth: 40,     
    },
    {
      headerName: "Test script name",
      field: "name",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      headerComponent: (props: any) => {
        return (
          <div className="text-[16px] truncate leading-[20.11px] font-semibold flex items-center">
            {props.displayName}
          </div>
        );
      },
      cellRenderer: (params: any) => {
        return (
          <div className=" truncate text-left" title={params.value}>
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "User story",
      field: "user_story",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      headerComponent: (props: any) => {
        return (
          <div className="text-[16px] leading-[20.11px] font-semibold flex items-center">
            {props.displayName}
          </div>
        );
      },
      cellRenderer: (params: any) => {
        return (
          <div className=" truncate text-left" title={params.value}>
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Epic",
      field: "epic",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      headerComponent: (props: any) => {
        return (
          <div className="text-[16px] leading-[20.11px] font-semibold flex items-center">
            {props.displayName}
          </div>
        );
      },
      cellRenderer: (params: any) => {
        return (
          <div className=" truncate text-left" title={params.value}>
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Total Test Steps",
      field: "total_test_steps",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      valueFormatter: (param: any) => toNumberOrNull(param?.value, "-"),
      type: "textColumn",
      cellRenderer: (params: any) => {
        return (
          <div
            className="flex truncate justify-center items-center w-full"
            title={params.value}
          >
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Run Time",
      field: "run_time",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      cellRenderer: (params: any) => {
        return (
          <div
            className="flex truncate justify-center items-center w-full"
            title={params.value}
          >
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Expected Start",
      field: "expected_start",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      cellRenderer: (params: any) => {
        return (
          <div
            className="flex truncate justify-center items-center w-full"
            title={params.value}
          >
            {params.value ? params.value : "-"}
          </div>
        );
      },
      // cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Date/ Time Executed",
      field: "date_time_executed",
      headerClass: "header-center",
      minWidth: 169,
      maxWidth: 169,
      cellRenderer: (params: any) => {
        return (
          <div
            className="flex truncate justify-center items-center w-full"
            title={params.value}
          >
            {params.value ? params.value : "-"}
          </div>
        );
      },
      // cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Status",
      field: "status",
      headerClass: "header-center-status",
      // cellStyle: { textAlign: "center" },
      cellClass: "flex w-full !justify-center",
      headerClassRules: { "custom-header-cell-label-style": () => true },
      minWidth: 153,
      maxWidth: 153,
      cellRenderer: (gridParam: any) => {
        const val = gridParam?.data?.status;
        return <>{val === "-" ? "-" : <Badge status={val} />}</>;
      },
    },
  ]);

  const nextHandler = () => {
    page < totalPages && setPage(page + 1);
  };
  const prevHandler = () => {
    page != 1 && setPage(page - 1);
  };

  const customStyles1 = {
    control: (base: any) => ({
      ...base,
      width: selectedEpic?.value ? "fit-content" : 62,
      height: 32,
      minHeight: 0,
      fontWeight: "600",
      borderRadius: "4px",
      outline: "none",
      fontSize: "14px",
      boxShadow: "none !important",
      background: selectedEpic?.value ? "black" : "",
      color: "#181B20",
      padding: "6px",
    }),

    placeholder: (styles: any) => ({
      ...styles,
      color: "#181B20",
      fontSize: 14,
      width: 26,
      height: 20,
      fontWeight: 600,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#181B20",
      padding: 0,
      width: 12,
      height: 12,
      alignItems: "center !important",
      marginLeft: -10,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: selectedEpic?.value ? "white" : "",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0px !important",
    }),
    input: (provided: any) => ({
      ...provided,
      padding: "0px !important",
      margin: "0px !important",
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "12px",
      fontWeight: "bold",
      width: "150px",
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        fontSize: "14px",
        fontWeight: "bold",
      };
    },
    indicatorSeparator: () => ({ display: "none" }),
  };
  const customStyles2 = {
    control: (base: any) => ({
      ...base,
      width: selectedStatus?.value ? "fit-content" : 74,
      height: 32,
      minHeight: 0,
      fontWeight: "600",
      borderRadius: "4px",
      fontSize: "14px",
      outline: "none",
      boxShadow: "none",
      background: selectedStatus?.value ? "black" : "",
      padding: "6px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0px !important",
    }),
    input: (provided: any) => ({
      ...provided,
      padding: "0px !important",
      margin: "0px !important",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#181B20",
      padding: 0,
      width: 12,
      height: 12,
      alignItems: "center !important",
      marginLeft: -10,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: selectedStatus?.value ? "white" : "",
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "#181B20",
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "12px",
      fontWeight: "bold",
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        fontSize: "16px",
        fontWeight: "bold",
      };
    },
    indicatorSeparator: () => ({ display: "none" }),
  };
  function formatRunTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);

    return `${minutes}min ${remainingSeconds}sec`;
  }
  const getTestingData = () => {
    const projectDataString = localStorage.getItem("projectData");
    const envDataString: string | null = localStorage.getItem("envData");
    const tokenString: string | null = localStorage.getItem("token");
    const projId: any =
      projectDataString !== null ? JSON.parse(projectDataString) : null;
    const envId: any =
      envDataString !== null ? JSON.parse(envDataString) : null;
    setLoading(true);
    axios
      .get(
        `${base_url}/api/v1/project/${projId?.value}/${
          envId?.value || ""
        }/testcase_executed?epic_id=${selectedEpic?.value || ""}&status=${
          selectedStatus?.value || ""
        }&page=${testingParamsList?.page}&size=${testingParamsList?.limit}`,
        {
          headers: {
            Authorization: `Bearer ${tokenString}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.data?.map((item: any) => {
            return {
              ...item,
              status: item?.status ? item?.status : "-",
              total_test_steps: item?.total_test_steps
                ? item?.total_test_steps
                : "-",
              run_time: item?.run_time ? formatRunTime(item?.run_time) : "-",
              expected_start: toDefaultDateFormat(
                item?.expected_start,
                DateTimeFormat.DATE_TIME,
                "-"
              ),
              date_time_executed: toDefaultDateFormat(
                item?.date_time_executed,
                DateTimeFormat.DATE_TIME,
                "-"
              ),
            };
          });
          const pageCount = Math.ceil(res?.data?.total / res?.data?.per_page);
          setPageCount(pageCount);
          setRowData(result);
          setTotal(res?.data?.total);
          setTotalPages(res?.data?.total_pages);
          setPerPage(res?.data?.per_page);
        }
      })
      .catch((e) => {
        setRowData([]);
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const onGridReady = (params: any) => {
    // Store a reference to the grid API
    gridRef.current = params.api;
  };

  const onRowSelect = (params: any) => {
    if (params?.type === "rowSelected") {
      if (params?.node?.selected) {
        params?.data?.id &&
          setSelectedRows((prev: any) => {
            if (!prev?.some?.((i: any) => i?.id === params?.data?.id))
              return [...prev, { ...params?.data, id: params?.data?.id }];
            return prev;
          });
      } else {
        setSelectedRows((prev: any) =>
          prev?.filter(
            (selectedRow: any) => selectedRow?.id !== params?.data?.id
          )
        );
      }
    }
  };
  const handleStartNow = () => {
    const payload = {
      test_case_ids: selectedRow?.map((item: any) => item.id),
      environment_id: selectedEnv?.value,
    };
    axios
      .post(`${base_url}/api/v1/testcase/bulk_execute`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Started successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const handleResume = () => {
    const payload = {
      test_case_ids: selectedRow?.map((item: any) => item.id),
    };
    axios
      .post(`${base_url}/api/v1/testcase/bulk_resume`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Resume successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
  };
  const handleSuspend = () => {
    const payload = {
      test_case_ids: selectedRow?.map((item: any) => item.id),
    };
    axios
      .post(`${base_url}/api/v1/testcase/bulk_pause`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Paused successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
  };
  const handleCancel = () => {
    const payload = {
      test_case_ids: selectedRow?.map((item: any) => item.id),
    };
    axios
      .post(`${base_url}/api/v1/testcase/bulk_cancel`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.length) {
          const trueCount = res?.data?.filter(
            (item: any) => item.success === true
          ).length;
          const falseCount = res?.data?.filter(
            (item: any) => item.success === false
          ).length;
          const valid = trueCount / selectedRow?.length;
          if (valid === 0) {
            toast.error(
              `${trueCount} of ${selectedRow?.length} are cancelled successfully`
            );
            return;
          }
          if (valid === 1) {
            toast.success(
              `${trueCount} of ${selectedRow?.length} are cancelled successfully`
            );
            return;
          } else
            toast.warning(
              `${trueCount} of ${selectedRow?.length} are cancelled successfully`
            );
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const handleDelete = () => {
    const payload = {
      test_case_ids: selectedRow?.map((item: any) => item.id),
    };
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json", // You can set the appropriate content type
        // Add any other headers if needed
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload), // Convert the payload object to a JSON string
    };
    fetch(`${base_url}/api/v1/testcase/bulk_delete`, requestOptions)
      .then((res) => {
        if (res?.status) {
          getTestingData();
          setSelectedRows([]);
          toast.success("Deleted successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const getEpicList = () => {
    if (selectedProject.length != 0) {
      axios
        .get(`${base_url}/api/v1/project/${selectedProject?.value}/epics`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const options = res?.data?.map((item: any) => {
              return {
                label: item.summary,
                value: item.id,
              };
            });
            setEpicsOptionList(options);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getEpicList();
    getTestingData();
    setSelectedRows([]);
    setPage(1);
  }, [selectedProject, selectedEnv, testingParamsList]);
  useEffect(() => {
    getTestingData();
  }, [page, selectedEpic, selectedStatus]);

  useEffect(() => {
    setTimeout(
      () =>
        gridRef.current?.forEachNode?.((node: any) => {
          node.setSelected(
            selectedRow.some((selected: any) => selected.id === node.data.id)
          );
        }),
      300
    );
  }, [rowData]);
  return (
    <>
      <div className="flex justify-between gap-1 mb-[16px]">
        <div className="flex gap-5">
          <div className="flex gap-[8px]">
            <Select
              components={{
                SingleValue: ({ children, ...prop }) => (
                  <components.SingleValue
                    // @ts-ignore
                    {...prop}
                  >
                    Epic: {children}
                  </components.SingleValue>
                ),
              }}
              onChange={setSelectedEpic}
              options={epicsOptionList}
              styles={customStyles1}
              value={selectedEpic}
              placeholder={"Epic"}
              className="leading-[19.6px]"
            />
            <Select
              components={{
                SingleValue: ({ children, ...prop }) => (
                  <components.SingleValue
                    // @ts-ignore
                    {...prop}
                  >
                    <div className="flex justify-center items-center gap-2">
                      {" "}
                      Status: {children}{" "}
                      <img
                        src={downArrow}
                        className="mb-[3px]"
                        alt="downArrow"
                      />
                    </div>
                  </components.SingleValue>
                ),
              }}
              onChange={setSelectedStatus}
              options={statusOptions}
              value={selectedStatus}
              styles={customStyles2}
              placeholder={"Status"}
              className="leading-[19.6px]"
            />

            {(selectedEpic || selectedStatus) && (
              <button
                className="bg-[#EDF0F5] flex  text-[14px] text-[#919EAB] items-center rounded-[4px] w-[85px] h-[32px] justify-center"
                onClick={() => {
                  setSelectedEpic(null);
                  setSelectedStatus(null);
                }}
              >
                <div className="flex gap-[2px] items-center">
                  <div className="w-[16px] h-[16px]">
                    <img src={closeIcon} alt="closeIcon" />
                  </div>
                  <span className="text-[14px]">Clear all </span>
                </div>
              </button>
            )}
            <div className="h-full w-[2px] border mx-2"></div>
            <div>
              <GridActions
                actions={[
                  {
                    label: "Start Now",
                    title: "",
                    callback() {
                      handleStartNow();
                    },
                    icon: <FiPlay size={20} />,
                  },
                  {
                    label: "Cancel Scripts",
                    title: "",
                    callback() {
                      handleCancel();
                    },
                    icon: <CloseIcon />,
                  },
                  {
                    label: "Suspend",
                    title: "",
                    callback() {
                      handleSuspend();
                    },
                    icon: <PauseIcon />,
                  },
                  {
                    label: "Resume",
                    title: "",
                    callback() {
                      handleResume();
                    },
                    icon: <AiOutlinePlayCircle size={20} />,
                  },
                  {
                    label: "Delete",
                    title: "",
                    callback() {
                      handleDelete();
                    },
                    icon: <TrashIcon />,
                  },
                ]}
                gridParams={"gridParams"}
                btnLabel="Actions"
                position="bottom center"
              />
            </div>
          </div>
        </div>

        <div className="flex gap-5 border rounded-lg w-[169px] h-[32px] py-1.5 flex justify-center">
          <Link
            to="/defect-management"
            className="text-[14px] text-[#919EAB] leading-[19.6px] w-[121px] h-[20px]"
          >
            <button onClick={() => null}>Defect Management</button>
          </Link>
        </div>
      </div>
      {EnvLoading || orgLoading || projectLoading ? (
        <div className="flex w-full justify-center items-center md:h-[560px] 2xl:h-[500px]">
          <Loader color={"#224BF8"} />
        </div>
      ) : (
        <div className="tableBh h-[696px] testingQueGrid">
          <AgGrid
            onGridReady={onGridReady}
            className=""
            columnDefs={columnDefs}
            rowData={rowData}
            domLayout={"autoHeight"}
            gridLoading={loading}
            // height="696px"
            fontSize="16px"
            perPage={perPage}
            pageCount={"1"}
            total={total}
            itemsPerPage={testingParamsList?.limit}
            handlePageClick={(page: any) => {
              setTestimgParamsList((prev: any) => ({
                ...prev,
                page: page?.selected + 1,
                forcePage: page?.selected,
              }));
            }}
            lineHeight="24px !important"
            fontFamily="font-sans"
            fontWeight="400"
            rowSelection={"multiple"}
            groupSelectsChildren={true}
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            totalPages={totalPages}
            onRowSelected={onRowSelect}
          />
        </div>
      )}
    </>
  );
};
export default Testing;
