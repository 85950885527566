// import { TabNavigator } from "@/components/TabNavigator";
import TabNavigator, { TabNavigatorTabType } from "../components/TabNavigator";
// import Testing from "@/components/testing";
import { useMemo } from "react";
import { HiPlus } from "react-icons/hi";
import Testing from "../components/Testing";

const TestingQueue = () => {
  const tabs: TabNavigatorTabType[] = useMemo(
    () => [
      {
        id: "",
        label: "Test Cycles",
        link: "/",
      },
      {
        id: "",
        label: "Defect Management",
        link: "/defect-management",
      },
      {
        id: "",
        label: "Testing Que",
        link: "/testing-queue",
        active: true,
        hideCredits: true,
      },
      {
        id: "",
        label: "Testing Server",
        link: "/testing-server",
      }
    ],
    []
  );
  return (
    <>
      <div className="h-full flex flex-col">
        <div className="flex justify-between gap-2 mb-[24px] items-center w-[1376px]">
          <div className="w-full max-w-2xl">
            <TabNavigator tabs={tabs} flex />
          </div>
          {/* <div className="">
            <button className="bg-[#004CFF] font-semibold text-white p-2 rounded-[8px] shadow-3xl">
              <HiPlus size={25} />
            </button>
          </div> */}
        </div>
        <div className=" w-[1376px]">
          <Testing />
        </div>
      </div>
    </>
  );
};
export default TestingQueue;
