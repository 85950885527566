import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import { AgGridReact, AgGridReactProps } from "ag-grid-react"; // the AG Grid React Component
// Optional theme CSS
import Loader from "../components/Loader";
import PaginatedItems from "./pagination";
const Grid = ({
  rowData,
  columnDefs,
  height,
  className,
  perPage,
  pageCount,
  nextHandler,
  totalPages,
  prevHandler,
  page,
  handlePageClick,
  total,
  forcePage,
  itemsPerPage,
  gridLoading,
  ...otherProps
}: any) => {
  const rowStyle: AgGridReactProps["rowStyle"] = {
    background: "white",
    borderRadius: "8px",
    borderTop: "2px solid #EDF0F5",
    borderBottom: "2px solid #EDF0F5",
    fontWeight: "400",
    fontSize: "14px",
    // marginTop: "2px", 
    lineHeight: "24px",
    minHeight: "44px",
    color: "#181B20",
    textAlign: "center",
    width: "1372px",
    height:"44px !important"
  };

  const gridOptions: AgGridReactProps["gridOptions"] = {
    headerHeight: 44,
    rowHeight: 45.5,
  };
  
  // ...
  
  <AgGridReact
    gridOptions={gridOptions}
    defaultColDef={{ suppressMovable: true, flex: 0 }}
    columnDefs={columnDefs}
    rowData={rowData}
    overlayNoRowsTemplate={"No data found"}
    className=".ag-overlay-loading-center h-full"
    tooltipShowDelay={0}
    tooltipHideDelay={2000}
    {...otherProps}
  />
  
  return (
    <div
      className={"ag-theme-alpine border-none " + className}
      style={{
        width: "100%",
        border: "none",
        // overflowY: "auto",
        height: height,
      }}
    >
      {gridLoading ? (
        <div
          className="flex justify-center items-center "
          
        >
          <Loader size={60} color={"#224BF8"} loading={gridLoading} />
        </div>
      ) : (
        <AgGridReact
          gridOptions={gridOptions}
          defaultColDef={{ suppressMovable: true, flex: 0 }}
          columnDefs={columnDefs}
          rowData={rowData}
          overlayNoRowsTemplate={"No data found"}
          className=".ag-overlay-loading-center h-full"
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          rowStyle={rowStyle}
          {...otherProps}
        />
      )}

      {rowData && rowData.length > 0 && (
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          totalCount={pageCount}
          handlePageClick={handlePageClick}
          page={page}
          rowData={rowData}
          forcePage={forcePage}
          totalPages={totalPages}
          total={total}
          nextHandler={nextHandler}
          prevHandler={prevHandler}
        />
      )}
    </div>
  );
};

export default Grid;
