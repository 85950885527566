import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
const Loader = ({color,loading,size}:any) => {
  return (
    <div>
      <PuffLoader
        color={color}
        loading={loading}
        size={size}
      />
    </div>
  );
};

export default Loader;