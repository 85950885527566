import { ChartData } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useMyContext } from "../myContext";
import Loader from "./Loader";
import ToogleSwitch from "./ToogleSwitch";
interface GraphProps {
  loading: Boolean;
  data: any;
}
const Graph = ({ loading, data }: GraphProps) => {
  const { token, selectedEnv, selectedProject, selectedOrg }: any =
    useMyContext();
  const base_url = process.env.REACT_APP_API_URL;
  const [toogleSwitch, setToogleSwitch] = useState<Boolean>(false);

  const filterZeroValues = (array: (number | undefined)[]) =>
    array.map((value) => (value ? value : null)) as number[];
  let data1InCount = [data?.completed_count, data?.remaining_count];
  let data1InPct = [data?.completed_percentage, data?.remaining_percentage];

  let data2InCount = filterZeroValues([
    data?.not_tested_yet_count,
    data?.pass_count,
    data?.fail_count,
  ]);

  let data2InPct = filterZeroValues([
    data?.not_tested_yet_percentage,
    data?.pass_percentage,
    data?.fail_percentage,
  ]);

  const data1: ChartData<"doughnut"> = {
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: !toogleSwitch
          ? filterZeroValues(data1InCount)
          : filterZeroValues(data1InPct),
        backgroundColor: ["#004CFF", "#919EAB"],
        label: "Completed",
        datalabels: {
          color: "#FFFFFF",
        },
      },
    ],
  };

  const data2: ChartData<"doughnut"> = {
    labels: ["Not Tested", "Pass", "Fail"],
    datasets: [
      {
        data: !toogleSwitch ? data2InCount : data2InPct,
        backgroundColor: [
          "rgb(145 158 171 / 0.2)",
          "rgb(38 210 55 / 0.6)",
          "rgb(235 23 92 / 0.6)",
        ],
        datalabels: {
          color: (ctx) => (ctx?.dataIndex === 0 ? "#181B20" : "white"),
        },
      },
    ],
  };

  const options: any = {
    cutout: 67.5,

    plugins: {
      dataLabels: {
        formatter: (value: any, context: any) => {
          return context.chart.data.labels[context.dataIndex];
        },
        color: "#fff",
        font: {
          size: 16,
        },
      },
    },
  };

  return (
    <div className="flex relative flex-col justify-center items-center border rounded-[12px] pt-[24px] pb-[20px] px-[32px] w-[536px] h-[344px]">
      <div className="flex justify-between item-center w-[472px] h-[24px]">
        <label className="leading-[24.3px] font-bold text-[18px] font-montserrat">
          Test execution results
        </label>
        <div className="flex justify-center gap-[9px] items-center h-[20px]">
          <ToogleSwitch setToogleSwitch={setToogleSwitch} />
          {/* <img src={switchIconToogleSwitch alt="switchIcon" /> */}
          <span className="-mt-[2px] w-[42px] h-[20px] leading-normal font-normal text-[16px] flex items-center text-[#181B20]">
            Pct, %
          </span>
        </div>
      </div>
      <div className="grow">
        {loading ? (
          <div className="flex justify-center items-center grow h-full w-full">
            <Loader size={60} color={"#224BF8"} loading={loading} />
          </div>
        ) : (
          <div className="h-[260px] w-[472px] items-center text-[18px] justify-evenly gap-[32px] mt-[16px] flex">
            <div className="flex flex-col gap-4 w-[220px] h-[260px]">
              <div className="!h-[220px] !w-[220px]">
                <div
                  style={{ position: "relative" }}
                  className="flex justify-center items-center"
                >
                  <img
                    src="/assets/circle/circle.svg"
                    alt=""
                    className="aspect-square w-full absolute pointer-events-none"
                  />
                  <Doughnut
                    plugins={[ChartDataLabels as any]}
                    data={data1}
                    options={options}
                    className="!w-[210.737px] !h-[210.737px]"
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      textAlign: "center",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "17.6px",
                      color: "#919EAB",
                    }}
                  >
                    <span>
                      Overall
                      <br />
                      Progress
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-around">
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 bg-[#004CFF] rounded-sm"></div>
                  <label className="text-[14px] font-sans-pro leading-[20px] text-[#181B20] font-normal">
                    Completed
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 bg-[#919EAB] rounded-sm"></div>
                  <label className="text-[14px] font-sans-pro leading-[20px] text-[#181B20] font-normal">
                    Remaining
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 !h-[260px]">
              <div className="!h-[220px] !w-[220px]">
                <div
                  style={{ position: "relative" }}
                  className="flex justify-center items-center"
                >
                  <img
                    src="/assets/circle/circle.svg"
                    alt=""
                    className="aspect-square w-full absolute pointer-events-none"
                  />
                  <Doughnut
                    plugins={[ChartDataLabels as any]}
                    data={data2}
                    options={options}
                    className="!w-[210.737px] !h-[210.737px]"
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      textAlign: "center",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "17.6px",
                      color: "#919EAB",
                    }}
                  >
                    <span>
                      Test Execution <br />
                      Results
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-around">
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 bg-[#919EAB]/20 rounded-sm"></div>
                  <label className="text-[14px] font-sans-pro leading-[20px] text-[#181B20] font-normal">
                    Not Tested
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 bg-[#26D237]/60 rounded-sm"></div>
                  <label className="text-[14px] font-sans-pro leading-[20px] text-[#181B20] font-normal">
                    Pass
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 bg-[#EB175C]/60 rounded-sm"></div>
                  <label className="text-[14px] font-sans-pro leading-[20px] text-[#181B20] font-normal">
                    Fail
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Graph;
