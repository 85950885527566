import React from "react";
interface ToogleSwitchProps {
  setToogleSwitch: any;
}
const ToogleSwitch = ({ setToogleSwitch }: ToogleSwitchProps) => {
  return (
    <div className="mt-[2px]">
      <label className="switch">
        <input
          type="checkbox"
          onChange={(e) => setToogleSwitch(e.target.checked)}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToogleSwitch;
