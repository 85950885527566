import { memo } from "react";
import { components } from "react-select";
const CustomSelectIcon: React.FC<any> = ({ children, ...rest }) => (
  <components.IndicatorsContainer {...rest}>
    <div className="flex aspect-square mr-3 items-center justify-center">
      <img
        alt=""
        src={
          rest?.selectProps?.menuIsOpen
            ? "/assets/icons/Arrow/Chevron/up.svg"
            : "/assets/icons/Arrow/Chevron/down.svg"
        }
        className="aspect-auto w-3 mb-2"
      />
    </div>
  </components.IndicatorsContainer>
);
export default memo(CustomSelectIcon);
