import { createContext, useContext } from "react";
interface MyContextValue {
  showCreditIndicators: boolean;
  setShowCreditIndicator: (val: boolean) => any;
}

export const LayoutContext = createContext<MyContextValue>({
  showCreditIndicators: false,
  setShowCreditIndicator: () => null,
});
export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
