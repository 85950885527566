import Select from "react-select/dist/declarations/src/Select";

export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SelectStyle1 = {
  control: (provided: any) => ({
    ...provided,
    height: "56px",
    borderRadius: "8px",
    fontSize: "18px",
    border: "1px solid rgba(145, 158, 171, 0.32)",
    zIndex: 30,
    outLine: "none",
    paddingLeft: "10px",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "5px",
    // Add some space between the control and the menu
    borderRadius: "10px",
    outLine: "none",
    zIndex: 99999,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    // Change this to your desired font size
    fontWeight: "thin", // Change this to your desired font weight
    border: "none",
    zIndex: 9999,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "black",
    marginRight: "10px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
};

export const SelectStyle2 = {
  control: (provided: any) => ({
    ...provided,
    height: "56px",
    borderRadius: "8px",
    fontSize: "18px",
    border: "1px solid rgba(145, 158, 171, 0.32)",
    zIndex: 10,
    outLine: "none",
    paddingLeft: "10px",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "5px",
    // Add some space between the control and the menu
    borderRadius: "10px",
    outLine: "none",
    zIndex: 20,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    // Change this to your desired font size
    fontWeight: "thin", // Change this to your desired font weight
    border: "none",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "black",
    marginRight: "10px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
};
export enum DateTimeFormat {
  DATE = "DD/MM/YYYY",
  DATE_TIME = "DD/MM/YYYY hh:mma",
}
export enum DatePickerFormat {
  DATE = "dd/MM/yyyy",
  DATE_TIME = "dd/MM/yyyy h:mma",
  TIME_24 = "HH:mm",
  TIME_12 = "hh:mm a",
  TIME_12O = "hh:mm",
}
