import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { LuChevronRight } from "react-icons/lu";
import { LuChevronLeft } from "react-icons/lu";

const PaginatedItems = ({
  itemsPerPage,
  page,
  total,
  nextHandler,
  prevHandler,
  totalPages,
  forcePage,
  handlePageClick,
}: any) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(total / itemsPerPage);

  useEffect(() => {
    const newOffset = currentPage * itemsPerPage;
    if (forcePage === 0) {
      setItemOffset(0);
      setCurrentPage(0);
    } else {
      setItemOffset(newOffset);
    }
  }, [currentPage, itemsPerPage, total, totalPages]);

  useEffect(() => {
    if (forcePage === 0) {
      setItemOffset(0);
    }
  }, [forcePage]);

  return (
    <>
      <div
        style={{ justifyContent: "flex-end" }}
        className="bg-white flex w-full items-center p-1 paginantion_"
      >
        <span className="mx-6 font-bold">
          {`${total === 0 ? "0" : itemOffset + 1} - ${Math.min(
            itemOffset + itemsPerPage,
            total
          )} of ${total} `}
        </span>
        <ReactPaginate
          nextLabel={
            <LuChevronRight
              style={{
                color: currentPage + 1 === pageCount ? "#a7b1bc" : "black",
                cursor:
                  currentPage + 1 === pageCount ? "not-allowed" : "pointer",
              }}
              size={20}
            />
          }
          onPageChange={(pageNo) => {
            setCurrentPage(pageNo.selected);
            handlePageClick(pageNo);
          }}
          pageCount={pageCount}
          previousLabel={
            <LuChevronLeft
              style={{
                color: currentPage === 0 ? "#a7b1bc" : "black",
                cursor: currentPage === 0 ? "not-allowed" : "pointer",
              }}
              size={20}
            />
          }
          containerClassName="pagination-container flex p-2 gap-x-5 mx-3"
          activeClassName="!text-[black] bg-blue-500"
          pageLinkClassName="custom-hidden"
          previousLinkClassName="px-3 py-1 rounded"
          nextLinkClassName="px-3 py-1 rounded"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default PaginatedItems;
