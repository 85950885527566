import React from 'react';

const TrashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons / Trash / Trash 3" opacity="0.8">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33341 13.3334C8.33341 13.7917 7.95841 14.1667 7.50008 14.1667C7.04175 14.1667 6.66675 13.7917 6.66675 13.3334V10.0001C6.66675 9.54175 7.04175 9.16675 7.50008 9.16675C7.95841 9.16675 8.33341 9.54175 8.33341 10.0001V13.3334ZM13.3334 13.3334C13.3334 13.7917 12.9584 14.1667 12.5001 14.1667C12.0417 14.1667 11.6667 13.7917 11.6667 13.3334V10.0001C11.6667 9.54175 12.0417 9.16675 12.5001 9.16675C12.9584 9.16675 13.3334 9.54175 13.3334 10.0001V13.3334ZM15.0001 15.8334C15.0001 16.2926 14.6267 16.6667 14.1667 16.6667H5.83341C5.37341 16.6667 5.00008 16.2926 5.00008 15.8334V6.66675H15.0001V15.8334ZM8.33341 3.60675C8.33341 3.47758 8.51175 3.33341 8.75008 3.33341H11.2501C11.4884 3.33341 11.6667 3.47758 11.6667 3.60675V5.00008H8.33341V3.60675ZM17.5001 5.00008H16.6667H13.3334V3.60675C13.3334 2.53675 12.3992 1.66675 11.2501 1.66675H8.75008C7.60091 1.66675 6.66675 2.53675 6.66675 3.60675V5.00008H3.33341H2.50008C2.04175 5.00008 1.66675 5.37508 1.66675 5.83342C1.66675 6.29175 2.04175 6.66675 2.50008 6.66675H3.33341V15.8334C3.33341 17.2117 4.45508 18.3334 5.83342 18.3334H14.1667C15.5451 18.3334 16.6667 17.2117 16.6667 15.8334V6.66675H17.5001C17.9584 6.66675 18.3334 6.29175 18.3334 5.83342C18.3334 5.37508 17.9584 5.00008 17.5001 5.00008Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default TrashIcon;
