import { useMemo } from "react";
import React from "react";
interface Props {}
const CreditIndicator: React.FC<Props> = ({}) => {
  const { credits, cost, month } = useMemo(() => {
    const date = new Date();
    const credits = 130_000?.toLocaleString("en-US"),
      cost = 0.04?.toLocaleString?.("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }),
      month = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
      });
    return { credits, cost, month };
  }, []);
  return <></>;
};
export default CreditIndicator;
